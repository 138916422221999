import { createStyles, withStyles, WithStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core/';
import classnames from 'classnames';
import * as React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { BETTING_RULES_URL, CONTACT_URL, PARTNERS_URL, PRIVACY_URL, RESPONSIBLE_GAMBLING_URL, TERMS_URL } from '../../constants';
import { KwiffTheme } from '../../themes/theme';

interface Props {
  isBetkwiffDomain: boolean;
}

class KwiffDetails2020 extends React.Component<Props & WithStyles<typeof styles> & WrappedComponentProps> {
  private openContactUrl = () => {
    window.open(CONTACT_URL);
  };

  private openPartnersUrl = () => {
    window.open(PARTNERS_URL);
  };

  private openTermsAndConditionsUrl = () => {
    window.open(TERMS_URL);
  };

  private openBettingRulesUrl = () => {
    window.open(BETTING_RULES_URL);
  };

  private openPrivacyNoticeUrl = () => {
    window.open(PRIVACY_URL);
  };

  private openResponsibleGamblingUrl = () => {
    window.open(RESPONSIBLE_GAMBLING_URL);
  };

  public render(): React.ReactNode {
    const { classes, isBetkwiffDomain } = this.props;

    return (
      <div data-testid="kwiff-details" className={classnames(classes.root, { [classes.noMargin]: isBetkwiffDomain })}>
        <Typography data-testid="kwiff-details-contact" className={classes.text} onClick={this.openContactUrl}>
          <FormattedMessage id="kwiff.contact" />
        </Typography>
        <Typography data-testid="kwiff-details-partners" className={classes.text} onClick={this.openPartnersUrl}>
          <FormattedMessage id="kwiff.partners" />
        </Typography>
        <Typography data-testid="kwiff-details-terms-and-conditions" className={classes.text} onClick={this.openTermsAndConditionsUrl}>
          <FormattedMessage id="kwiff.t_c" />
        </Typography>
        <Typography data-testid="kwiff-details-betting" className={classes.text} onClick={this.openBettingRulesUrl}>
          <FormattedMessage id="kwiff.betting" />
        </Typography>
        <Typography data-testid="kwiff-details-privacy" className={classes.text} onClick={this.openPrivacyNoticeUrl}>
          <FormattedMessage id="kwiff.privacy" />
        </Typography>
        <Typography data-testid="kwiff-details-responsible" className={classes.text} onClick={this.openResponsibleGamblingUrl}>
          <FormattedMessage id="kwiff.responsible" />
        </Typography>
      </div>
    );
  }
}
/* Component Styling */
const styles = (theme: KwiffTheme) => {
  return createStyles({
    root: {
      margin: `${theme.spacing(5)}px 0`,
      display: 'grid',
      justifyItems: 'center',
      gridTemplateRows: 'repeat(6, 1fr)',
      gridRowGap: theme.spacing(1.5),
      [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
        gridTemplateRows: '1fr 1fr',
        gridTemplateColumns: '1fr 1fr 1fr',
      },
    },
    text: {
      color: theme.palette.common.white,
      fontSize: '15px',
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    noMargin: {
      [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
        margin: 0,
      },
    },
  });
};
export default withStyles(styles, { withTheme: true })(injectIntl(KwiffDetails2020));
