import { withTheme } from '@material-ui/core';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import * as React from 'react';
import { KwiffTheme } from '../../themes/theme';
import { defaultScrollbarsOptions, OverlayScrollbarsComponentOptions } from '../../utils/scrollbar';

interface Props {
  theme: KwiffTheme;
  classes?: string;
  options?: OverlayScrollbarsComponentOptions;
}

const scrollBar: React.FunctionComponent<Props> = (props: React.PropsWithChildren<Props>) => (
  <OverlayScrollbarsComponent
    data-testid="overlay-scrollbars-component"
    options={{
      scrollbars: props.options && props.options.scrollbars ? props.options.scrollbars : defaultScrollbarsOptions.scrollbars,
      overflowBehavior: props.options && props.options.overflowBehavior ? props.options.overflowBehavior : undefined,
    }}
    className={props.classes}
  >
    {props.children}
  </OverlayScrollbarsComponent>
);

// tslint:disable-next-line:variable-name
export const ScrollBar = withTheme(scrollBar);
export default ScrollBar;
