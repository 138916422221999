import * as React from 'react';
import CookiesConsentModeDialog from '../snackbars/2021/CookiesConsentModeDialog';
import CookiesConsentModeSnackbar from '../snackbars/2021/CookiesConsentModeSnackbar';

class PromptsHandler extends React.Component {
  public render(): React.ReactNode {
    return (
      <React.Fragment>
        <CookiesConsentModeSnackbar />
        <CookiesConsentModeDialog />
      </React.Fragment>
    );
  }
}

export default PromptsHandler;
