import { withTheme } from '@material-ui/core';
import * as React from 'react';
import { KwiffTheme } from '../../themes/theme';

interface Props {
  theme: KwiffTheme;
  color: string;
  backgroundColor: string;
  text: string;
}

const circle: React.FunctionComponent<Props> = ({ color, backgroundColor, text }) => {
  return (
    <div
      data-testid="circle"
      style={{
        backgroundColor,
        color,
        width: '80px',
        height: '80px',
        borderRadius: '50%',
      }}
    >
      <span
        style={{ textAlign: 'center', height: '80px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '20px' }}
      >
        {text}
      </span>
    </div>
  );
};

// tslint:disable-next-line:variable-name
export const Circle = withTheme(circle);
export default Circle;
