import { store } from './store';
import {
  CLOSE_CONSENT_PROMPTS,
  SET_CONSENT_DIALOG_OPEN,
  SET_CONSENT_SNACKBAR_OPEN,
  SET_IS_BETKWIFF_DOMAIN,
  SET_IS_CASINO_DOMAIN,
  SET_USER_GEO_LOCATION,
  SET_WEBAPP_ORIGIN,
} from './types';

export const setUserGeoLocation = (meta: string) => {
  store.dispatch({
    meta,
    type: SET_USER_GEO_LOCATION,
  });
};

export const setIsCasinoDomain = (meta: boolean) => {
  store.dispatch({
    meta,
    type: SET_IS_CASINO_DOMAIN,
  });
};

export const setIsBetkwiffDomain = (meta: boolean) => {
  store.dispatch({
    meta,
    type: SET_IS_BETKWIFF_DOMAIN,
  });
};

export const setWebAppOrigin = (meta: string) => {
  store.dispatch({
    meta,
    type: SET_WEBAPP_ORIGIN,
  });
};

export const setCookieSnackbar = (meta: boolean) => {
  store.dispatch({
    meta,
    type: SET_CONSENT_SNACKBAR_OPEN,
  });
};

export const setCookieConsentDialog = (meta: boolean) => {
  store.dispatch({
    meta,
    type: SET_CONSENT_DIALOG_OPEN,
  });
};

export const closeConsentPrompts = () => {
  store.dispatch({
    type: CLOSE_CONSENT_PROMPTS,
  });
};
