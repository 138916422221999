import { createStyles, Typography, withStyles, WithStyles } from '@material-ui/core';
import classnames from 'classnames';
import * as React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { KwiffTheme } from '../../themes/theme';
import JoinButton from '../buttons/JoinButton';

interface Props {
  theme: KwiffTheme;
  onScrollToTermsAndConditions: () => void;
  isBetkwiffDomain: boolean;
  isCasinoDomain: boolean;
}

class SurpriseBetInformation2021 extends React.Component<Props & WithStyles<typeof styles> & WrappedComponentProps> {
  private scrollToTermsAndConditions = () => {
    this.props.onScrollToTermsAndConditions();
  };

  public render(): React.ReactNode {
    const { classes, isBetkwiffDomain } = this.props;

    return (
      <div data-testid="surprise-bet-information" className={classes.root}>
        <div data-testid="surprise-bet-information-signup-button" className={classes.joinButton}>
          <JoinButton renderJoinHere={true} isBetkwiffDomain={isBetkwiffDomain} />
        </div>
        <span className={classes.termsApplyTextContainer}>
          <Typography
            data-testid="surprise-bet-information-terms-apply"
            className={classnames(classes.stakeText, classes.termsText)}
            onClick={this.scrollToTermsAndConditions}
          >
            <FormattedMessage id="terms.apply" />
          </Typography>
        </span>
      </div>
    );
  }
}

const styles = (theme: KwiffTheme) => {
  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifySelf: 'center',
      marginTop: theme.spacing(3),
    },
    surpriseBetContent: {
      margin: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    },
    surpriseBetText: {
      color: theme.palette.common.white,
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '30px',
      [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
        fontSize: '50px',
      },
    },
    wagerInformation: {
      fontSize: '15px !important',
    },
    joinButton: {
      display: 'flex',
      justifyContent: 'start',
      [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
        justifyContent: 'center',
      },
    },
    stakeText: {
      color: theme.palette.common.white,
      textAlign: 'start',
      fontWeight: 'bold',
      fontSize: '13px',
      [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
        textAlign: 'center',
      },
    },
    termsText: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    newCustomers: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      textAlign: 'center',
      fontWeight: 'bold',
      marginBottom: theme.spacing(1.5),
      fontSize: 30,
      [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        fontSize: 20,
      },
    },
    surpriseBetContainer: {
      display: 'grid',
      gridTemplateRows: 'repeat(4, auto)',
      justifyItems: 'left',
      gridRowGap: theme.spacing(0.5),
      [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
        justifyItems: 'center',
      },
    },
    termsApplyTextContainer: {
      display: 'flex',
      margin: `${theme.spacing(1)}px 0`,
      [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
        justifyContent: 'center',
      },
    },
  });
};
export default withStyles(styles, { withTheme: true })(injectIntl(SurpriseBetInformation2021));
