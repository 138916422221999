import { Button, Snackbar, SnackbarContent, Typography } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { PRIVACY_URL } from '../../../constants';
import { KwiffTheme } from '../../../themes/theme';

interface Props {
  onClose: (accept: boolean) => void;
}

class CookiesConsentSnackbar extends React.Component<Props & WithStyles<typeof styles> & WrappedComponentProps> {
  private handleCloseDialog = (accept: boolean) => () => {
    this.props.onClose(accept);
  };

  private privacyPolicyRedirect = () => {
    window.open(PRIVACY_URL, '_blank');
  };

  public render(): React.ReactNode {
    const { classes } = this.props;

    return (
      <Snackbar data-testid="cookies-consent-snackbar" open={true} className={classes.root}>
        <SnackbarContent
          className={classes.contentContainer}
          message={
            <React.Fragment>
              <Typography className={classes.infoText} data-testid="cookies-consent-message">
                <FormattedMessage id="cookie_notice.message" />
              </Typography>
              <Typography
                data-testid="cookies-consent-learn-more"
                className={classes.snackBarLinkText}
                onClick={this.privacyPolicyRedirect}
              >
                <FormattedMessage id="cookie_notice.learn_more" />
              </Typography>
            </React.Fragment>
          }
          action={
            <div className={classes.buttons}>
              <Button
                data-testid="cookies-consent-accept"
                className={classes.button}
                variant="contained"
                color="primary"
                id="accept-btn"
                type="button"
                onClick={this.handleCloseDialog(true)}
              >
                <FormattedMessage id="common.accept" />
              </Button>
              <Button
                data-testid="cookies-consent-close"
                className={classes.button}
                variant="contained"
                id="close-btn"
                type="button"
                onClick={this.handleCloseDialog(false)}
              >
                <FormattedMessage id="common.close" />
              </Button>
            </div>
          }
        />
      </Snackbar>
    );
  }
}

const styles = (theme: KwiffTheme) => {
  return createStyles({
    root: {
      margin: '8px',
      [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
        width: '100%',
      },
    },
    contentContainer: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    infoText: {
      fontSize: '14px',
    },
    buttons: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      gridColumnGap: theme.spacing(2),
      [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
        marginRight: theme.spacing(2),
      },
    },
    button: {
      boxShadow: theme.shadows[0],
    },
    snackBarLinkText: {
      fontSize: '14px',
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  });
};

export default withStyles(styles)(injectIntl(CookiesConsentSnackbar));
