import * as React from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl, WrappedComponentProps } from 'react-intl';

class MetaInfo extends React.Component<WrappedComponentProps> {
  private renderContentByLocation = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    return (
      <Helmet>
        <title> {formatMessage({ id: 'metadata.title' })}</title>
        <meta name="description" content={formatMessage({ id: 'metadata.description' })} />
      </Helmet>
    );
  };

  public render(): React.ReactNode {
    return <>{this.renderContentByLocation()}</>;
  }
}

export default injectIntl(MetaInfo);
