import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Switch, Theme, Typography } from '@material-ui/core';
import { WithStyles } from '@material-ui/core/';
import { createStyles, withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { CONSENT_MODE_TYPE } from '../../../data-types/cookies/cookiesEnums';
import { closeConsentPrompts, setCookieConsentDialog } from '../../../state/actions';
import { TStore } from '../../../state/types';
import { setConsentMode } from '../../../utils/cookies';

interface Props {
  consentDialogOpen: boolean;
}

interface State {
  analyticsChecked: boolean;
  marketingChecked: boolean;
  preferencesChecked: boolean;
}

class CookiesConsentModeDialog extends React.Component<Props & WithStyles<typeof styles> & WrappedComponentProps, State> {
  public state: State = {
    analyticsChecked: false,
    marketingChecked: false,
    preferencesChecked: false,
  };

  public componentWillUnmount = () => {
    this.setAnalyticsChecked = (_: boolean) => undefined;
    this.setMarketingChecked = (_: boolean) => undefined;
    this.setPreferencesChecked = (_: boolean) => undefined;
  };

  private setAnalyticsChecked = (analyticsChecked: boolean) => this.setState({ analyticsChecked });

  private setMarketingChecked = (marketingChecked: boolean) => this.setState({ marketingChecked });

  private setPreferencesChecked = (preferencesChecked: boolean) => this.setState({ preferencesChecked });

  private handleConsentChoice = (acceptAll: boolean = false, necessaryOnly: boolean = false) => {
    const { analyticsChecked, marketingChecked, preferencesChecked } = this.state;

    closeConsentPrompts();
    if (acceptAll) {
      setConsentMode({ type: CONSENT_MODE_TYPE.ALL, necessary: true, marketing: true, analytics: true, preferences: true });
    } else if (necessaryOnly) {
      setConsentMode({
        type: CONSENT_MODE_TYPE.NECESSARY,
        necessary: true,
        marketing: false,
        analytics: false,
        preferences: false,
      });
    } else {
      setConsentMode({
        type: CONSENT_MODE_TYPE.CUSTOM,
        necessary: true,
        marketing: marketingChecked,
        analytics: analyticsChecked,
        preferences: preferencesChecked,
      });
    }
    setCookieConsentDialog(false);
  };

  private handleAcceptAll = () => this.handleConsentChoice(true);

  private handleAcceptNecessary = () => this.handleConsentChoice(false, true);

  private handleAcceptCustom = () => this.handleConsentChoice(false, false);

  private handleAnalyticsCookies = () => {
    const { analyticsChecked } = this.state;

    this.setAnalyticsChecked(!analyticsChecked);
  };

  private handleMarketingCookies = () => {
    const { marketingChecked } = this.state;

    this.setMarketingChecked(!marketingChecked);
  };

  private handlePreferencesCookies = () => {
    const { preferencesChecked } = this.state;

    this.setPreferencesChecked(!preferencesChecked);
  };

  public render(): React.ReactNode {
    const { classes, consentDialogOpen } = this.props;
    const { analyticsChecked, marketingChecked, preferencesChecked } = this.state;

    return (
      <div>
        <Dialog open={consentDialogOpen} disableEscapeKeyDown={true} classes={{ paper: classes.paper }}>
          <DialogTitle>
            <Typography className={classes.boldText}>
              <FormattedMessage id="cookies.manage_cookies" />
            </Typography>
          </DialogTitle>
          <DialogContent>
            <div className={classes.choice}>
              <div className={classes.header}>
                <Typography className={classes.boldText}>
                  <FormattedMessage id="cookies.strictly_necessary" />
                </Typography>
                <Typography className={classes.greenText}>
                  <FormattedMessage id="cookies.always_active" />
                </Typography>
              </div>
              <div>
                <Typography className={classes.blackText}>
                  <FormattedMessage id="cookies.necessary" />
                </Typography>
              </div>
              <div className={classes.fullWidth}>
                <Button
                  className={`${classes.fullWidth} consent-choice`}
                  variant="contained"
                  color="primary"
                  onClick={this.handleAcceptAll}
                  classes={{
                    label: 'consent-choice',
                  }}
                >
                  <FormattedMessage id="cookies.accept_all" />
                </Button>
              </div>
            </div>
            <div>
              <div className={classes.choice}>
                <div className={classes.header}>
                  <Typography className={classes.boldText}>
                    <FormattedMessage id="cookies.analytics_cookies" />
                  </Typography>
                  <Switch checked={analyticsChecked} size="small" color="primary" onChange={this.handleAnalyticsCookies} />
                </div>
                <div>
                  <Typography className={classes.blackText}>
                    <FormattedMessage id="cookies.analytics" />
                  </Typography>
                </div>
              </div>
              <div className={classes.choice}>
                <div className={classes.header}>
                  <Typography className={classes.boldText}>
                    <FormattedMessage id="cookies.preferences_cookies" />
                  </Typography>
                  <Switch checked={preferencesChecked} size="small" color="primary" onChange={this.handlePreferencesCookies} />
                </div>
                <div>
                  <Typography className={classes.blackText}>
                    <FormattedMessage id="cookies.preferences" />
                  </Typography>
                </div>
              </div>
              <div className={classes.choice}>
                <div className={classes.header}>
                  <Typography className={classes.boldText}>
                    <FormattedMessage id="cookies.marketing_cookies" />
                  </Typography>
                  <Switch checked={marketingChecked} size="small" color="primary" onChange={this.handleMarketingCookies} />
                </div>
                <div>
                  <Typography className={classes.blackText}>
                    <FormattedMessage id="cookies.marketing" />
                  </Typography>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div className={classes.actions}>
              <Button
                variant="contained"
                color="primary"
                className={'consent-choice'}
                onClick={this.handleAcceptCustom}
                classes={{
                  label: 'consent-choice',
                }}
              >
                <FormattedMessage id="cookies.confirm_choices" />
              </Button>
              <Button
                className={'consent-choice'}
                variant="text"
                color="primary"
                onClick={this.handleAcceptNecessary}
                classes={{
                  label: 'consent-choice',
                }}
              >
                <FormattedMessage id="cookies.allow_necessary" />
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      maxWidth: '550px',
    },
    boldText: {
      fontWeight: 'bold',
      color: theme.palette.common.black,
      fontSize: '14px',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    greenText: {
      fontWeight: 'bold',
      color: 'green',
      fontSize: '14px',
    },
    blackText: {
      fontSize: '14px',
      color: theme.palette.common.black,
    },
    fullWidth: {
      width: '100%',
    },
    actions: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    choice: {
      paddingTop: theme.spacing(2),
    },
  });

const mapStateToProps = (store: TStore) => ({
  consentDialogOpen: store.consentDialogOpen,
});

export default connect(mapStateToProps)(withStyles(styles)(injectIntl(CookiesConsentModeDialog)));
