import * as React from 'react';
import { GOOGLE_TAG_MANAGER_API_KEY } from '../../utils/initialisation';

class ThirdParties extends React.Component {
  public render(): React.ReactNode {
    return (
      <React.Fragment>
        {GOOGLE_TAG_MANAGER_API_KEY && (
          <iframe
            title="google-tag-manager"
            data-testid="google-tag-manager-iframe"
            src={`https://www.googletagmanager.com/ns.html?id=${GOOGLE_TAG_MANAGER_API_KEY}`}
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          />
        )}
      </React.Fragment>
    );
  }
}

export default ThirdParties;
