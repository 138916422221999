export enum UrlSearchParamType {
  SIGNUP = 'signup',
  CASINO_FIRST = 'casinofirst',
  BTAG = 'btag',
  SOURCE = 'source',
  AFF_ID = 'affid',
  SOURCE_ID = 'sourceid',
  SITE_ID = 'siteid',
  A_ID = 'aid',
  BANNER_ID = 'bannerid',
}
