export enum AcceptedCountries {
  UNITED_KINGDOM = 'GB',
  IRELAND = 'IE',
  NEW_ZEALAND = 'NZ',
  CANADA = 'CA',
  BRAZIL = 'BR',
  INDIA = 'IN',
  FINLAND = 'FI',
  GERMANY = 'DE',
  UGANDA = 'UG',
  KENYA = 'KE',
  MEXICO = 'MX',
  COLOMBIA = 'CO',
  CHILE = 'CL',
  PERU = 'PE',
  ARGENTINA = 'AR',
  ECUADOR = 'EC',
  EL_SALVADOR = 'SV',
  PARAGUAY = 'PY',
  URUGUAY = 'UY',
}

export enum CURRENCY_MULTIPLIER {
  INDIA = 100,
  BRAZIL = 10,
  NEW_ZEALAND = 2,
}

export enum BANNERNOW_SOURCE {
  DESKTOP = 'https://storage.bannernow.com/dt/html/dtp_cl13ha20t000zn6o3jvy06pm4/embed.html?responsive=1&bnTag=',
  DESKTOP_CASINO = 'https://storage.bannernow.com/dt/html/dtp_cl14pgu6h001hlno3y96yroug/embed.html?responsive=1&bnTag=',
  MOBILE = 'https://storage.bannernow.com/dt/html/dtp_cl13nd8u80012g2o3fuvddcws/embed.html?responsive=1&bnTag=',
  MOBILE_CASINO = 'https://storage.bannernow.com/dt/html/dtp_cl14pgu6h001ilno3lw2rpk5s/embed.html?responsive=1&bnTag=',
}

// Agreed with James to have fix sizes for the banners when they are set up in banner now
// Desktop bannernow size: 1920x700
// Mobile bannernow size: 315x315
// In order to give the iframe a correct size we have to calculate the ratio of the height
// based on the banner dimensions and the screen dimension
export enum BANNERNOW_HEIGHT_RATIO {
  DESKTOP = '0.3645',
  MOBILE = '100vw',
}
