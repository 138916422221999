import { AffiliateSourceId, AffiliateSourceLabel } from '../data-types/affiliates/affiliatesEnums';

export const getSourceIdByName = (source: string) => {
  switch (source.toLowerCase()) {
    case AffiliateSourceLabel.INCOME_ACCESS.toLowerCase():
      return AffiliateSourceId.INCOME_ACCESS;
    case AffiliateSourceLabel.ADJUST.toLowerCase():
      return AffiliateSourceId.ADJUST;
    default:
      return source;
  }
};
