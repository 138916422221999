import 'overlayscrollbars/css/OverlayScrollbars.css';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import { store } from './state/store';
import { initialise } from './utils/initialisation';
import './utils/styles/scrollBarStyles.css';

initialise()
  .then(() => {
    ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      document.getElementById('root'),
    );
  })
  .catch(() => {
    ReactDOM.render(<div>Couldn't load the page :(</div>, document.getElementById('root'));
  });
