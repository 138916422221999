import { Button, Theme, Typography } from '@material-ui/core';
import { WithStyles } from '@material-ui/core/';
import { createStyles, withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import * as React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { getBettingRulesLinkByCountry, getTermsLinkByCountry } from '../../utils/i18n';
import Divider from '../utils/Divider';

interface Props {
  isCasinoDomain: boolean;
  isBetkwiffDomain: boolean;
}

class Terms2021 extends React.Component<Props & WithStyles<typeof styles> & WrappedComponentProps> {
  private openPage = (link: string) => () => {
    window.open(link, '_blank');
  };

  private generateSpanElementWithLink = (textId: string, link: string) => (
    <span data-testid={`tc-${textId}`} onClick={this.openPage(link)}>
      <FormattedMessage id={textId} />
    </span>
  );

  private generateGeneralTermsSubListItems = (index: number) => {
    const {
      isCasinoDomain,
      intl: { formatMessage },
    } = this.props;
    const subTerms: React.ReactNode[] = [];

    let subItemIndex = 1;
    let translationKey = `terms_and_conditions${isCasinoDomain ? '.casino' : ''}.general_tc.${index}.${subItemIndex}`;
    let translatedText = formatMessage({ id: translationKey });
    while (translationKey !== translatedText && translatedText) {
      subTerms.push(
        <li>
          <Typography data-testid={`tc-general-${index}-${subItemIndex}`}>{translatedText}</Typography>
        </li>,
      );
      subItemIndex += 1;
      translationKey = `terms_and_conditions${isCasinoDomain ? '.casino' : ''}.general_tc.${index}.${subItemIndex}`;
      translatedText = formatMessage({ id: translationKey });
    }

    return subTerms;
  };

  private generateGeneralTerms = () => {
    const {
      classes,
      isCasinoDomain,
      intl: { formatMessage },
    } = this.props;
    const terms: React.ReactNode[] = [];

    let index = 1;
    let translationKey = `terms_and_conditions${isCasinoDomain ? '.casino' : ''}.general_tc.${index}`;
    let translatedText = formatMessage({ id: translationKey });
    while (translationKey !== translatedText && translatedText) {
      terms.push(
        <div className={classes.point}>
          <Typography data-testid={`tc-general-${index}`}>{translatedText}</Typography>
          <ul className={classes.list}>{this.generateGeneralTermsSubListItems(index).map(element => element)}</ul>
        </div>,
      );
      index += 1;
      translationKey = `terms_and_conditions${isCasinoDomain ? '.casino' : ''}.general_tc.${index}`;
      translatedText = formatMessage({ id: translationKey });
    }

    return terms;
  };

  private generateWelcomeTermsSubListItems = (index: number) => {
    const {
      isCasinoDomain,
      intl: { formatMessage },
    } = this.props;
    const subTerms: React.ReactNode[] = [];

    let subItemIndex = 1;
    let translationKey = `terms_and_conditions${isCasinoDomain ? '.casino' : ''}.welcome_tc.${index}.${subItemIndex}`;
    let translatedText = formatMessage({ id: translationKey });
    while (translationKey !== translatedText && translatedText) {
      subTerms.push(
        <li>
          <Typography data-testid={`tc-welcome-${index}-${subItemIndex}`}>{translatedText}</Typography>
        </li>,
      );
      subItemIndex += 1;
      translationKey = `terms_and_conditions${isCasinoDomain ? '.casino' : ''}.welcome_tc.${index}.${subItemIndex}`;
      translatedText = formatMessage({ id: translationKey });
    }

    return subTerms;
  };

  private generateWelcomeTerms = () => {
    const {
      classes,
      isCasinoDomain,
      intl: { formatMessage },
    } = this.props;
    const terms: React.ReactNode[] = [];

    let index = 1;
    let translationKey = `terms_and_conditions${isCasinoDomain ? '.casino' : ''}.welcome_tc.${index}`;
    let translatedText = formatMessage({ id: translationKey });
    while (translationKey !== translatedText && translatedText) {
      terms.push(
        <div className={classes.point}>
          <Typography data-testid={`tc-welcome-${index}`}>{translatedText}</Typography>
          <ul className={classes.list}>{this.generateWelcomeTermsSubListItems(index).map(element => element)}</ul>
        </div>,
      );
      index += 1;
      translationKey = `terms_and_conditions${isCasinoDomain ? '.casino' : ''}.welcome_tc.${index}`;
      translatedText = formatMessage({ id: translationKey });
    }

    return terms;
  };

  private renderTerms = () => {
    const {
      classes,
      isBetkwiffDomain,
      intl: { formatMessage },
    } = this.props;

    return (
      <>
        <div className={classes.terms}>
          <Typography data-testid="tc-welcome-title" className={classes.title} variant="h4">
            <FormattedMessage id="terms_and_conditions.welcome_tc" />
          </Typography>
          <Typography
            className={classes.point}
            data-testid="tc-welcome-intro"
            dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'terms_and_conditions.welcome_tc.intro' }) }}
          />
          <React.Fragment>{this.generateWelcomeTerms().map(element => element)}</React.Fragment>
        </div>

        <Divider width="90%" isBetkwiffDomain={isBetkwiffDomain} />

        <div className={classes.terms}>
          <Typography data-testid="tc-general-title" className={classes.title} variant="h4">
            <FormattedMessage id="terms_and_conditions.general_tc" />
          </Typography>
          <React.Fragment>{this.generateGeneralTerms().map(element => element)}</React.Fragment>
          <div className={classes.row}>
            <Button className={classes.button}>{this.generateSpanElementWithLink('terms.t_and_c', getTermsLinkByCountry())}</Button>
            <Button className={classes.button}>
              {this.generateSpanElementWithLink('terms.betting_rules', getBettingRulesLinkByCountry())}
            </Button>
          </div>
          <Typography data-testid="tc-last-update" className={classnames(classes.point, classes.lastUpdate)}>
            <FormattedMessage id="terms_and_conditions.last_update" />
          </Typography>
        </div>
      </>
    );
  };

  private renderTermsCasino = () => {
    const {
      classes,
      isBetkwiffDomain,
      intl: { formatMessage },
    } = this.props;

    return (
      <>
        <div className={classes.terms}>
          <Typography data-testid="tc-welcome-title" className={classes.title} variant="h4">
            <FormattedMessage id="terms_and_conditions.casino.welcome_tc" />
          </Typography>
          <Typography
            className={classes.point}
            data-testid="tc-welcome-intro"
            dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'terms_and_conditions.casino.welcome_tc.intro' }) }}
          />
          <React.Fragment>{this.generateWelcomeTerms().map(element => element)}</React.Fragment>
        </div>

        <Divider width="90%" isBetkwiffDomain={isBetkwiffDomain} />

        <div className={classes.terms}>
          <Typography data-testid="tc-general-title" className={classes.title} variant="h4">
            <FormattedMessage id="terms_and_conditions.casino.general_tc" />
          </Typography>
          <React.Fragment>{this.generateGeneralTerms().map(element => element)}</React.Fragment>
          <div className={classes.row}>
            <Button className={classes.button}>{this.generateSpanElementWithLink('terms.t_and_c', getTermsLinkByCountry())}</Button>
            <Button className={classes.button}>
              {this.generateSpanElementWithLink('terms.betting_rules', getBettingRulesLinkByCountry())}
            </Button>
          </div>
          <Typography data-testid="tc-last-update" className={classnames(classes.point, classes.lastUpdate)}>
            <FormattedMessage id="terms_and_conditions.casino.last_update" />
          </Typography>
        </div>
      </>
    );
  };

  public render(): React.ReactNode {
    const { isCasinoDomain } = this.props;

    return <div data-testid="tc-div">{isCasinoDomain ? this.renderTermsCasino() : this.renderTerms()}</div>;
  }
}

const styles = (theme: Theme) => {
  return createStyles({
    terms: {
      margin: `0 auto ${theme.spacing(5)}px auto`,
      width: '70%',
      [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        width: '90%',
      },
    },
    title: {
      textAlign: 'center',
      fontWeight: 'bold',
      margin: `${theme.spacing(5)}px 0`,
    },
    point: {
      padding: `${theme.spacing(0.6)}px 0`,
      margin: `${theme.spacing(2)}px 0`,
    },
    lastUpdate: {
      margin: `${theme.spacing(5)}px 0`,
      textAlign: 'end',
    },
    list: {
      margin: 0,
      padding: theme.spacing(0, 0, 0, 2),
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    button: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      borderRadius: 5,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  });
};

export default withStyles(styles)(injectIntl(Terms2021));
