export const APP_PATH = '/';

export const PATHS = {
  ROOT: APP_PATH,
  ROOT_OPTIONAL_ARG: `${APP_PATH}:btag?:affid?:source?`,
  ROOT_PARAM: (btag: string, affid: string | undefined, source: string | undefined) =>
    `${APP_PATH}?btag=${btag}&affid=${affid}&source=${source}`,
  OFFER: {
    YEAR_2020: `${APP_PATH}offer/2020/`,
    YEAR_2020_OPTIONAL_ARG: `${APP_PATH}offer/2020/:btag?`,
  },
};

export const availablePaths = [PATHS.ROOT, PATHS.OFFER.YEAR_2020];
