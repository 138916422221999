import * as React from 'react';
import { mobileMaxResolution } from '../../themes/theme';

export enum Layout {
  MOBILE,
  DESKTOP,
}
export enum Orientation {
  LANDSCAPE,
  PORTRAIT,
}

interface Props {
  onLayoutChanged?: (layout: Layout) => void;
  onOrientationChanged?: (orientation: Orientation) => void;
  onWidthChange?: (width: number) => void;
}

interface State {
  currentLayout: Layout;
  currentOrientation: Orientation;
}

export class ResizeHandler extends React.Component<Props, State> {
  public state = {
    currentLayout: Layout.MOBILE,
    currentOrientation: Orientation.PORTRAIT,
  };

  public componentDidMount(): void {
    this.setLayoutData();
    window.addEventListener('resize', this.setLayoutData);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', this.setLayoutData);
  }

  private getLayoutType = (): Layout => {
    if (window.innerWidth >= mobileMaxResolution) {
      return Layout.DESKTOP;
    }
    return Layout.MOBILE;
  };

  private getOrientationType = (): Orientation => {
    if (window.innerWidth > window.innerHeight) {
      return Orientation.LANDSCAPE;
    }
    return Orientation.PORTRAIT;
  };

  private setLayoutData = (): void => {
    if (this.props.onLayoutChanged) {
      const layout = this.getLayoutType();
      if (layout !== this.state.currentLayout) {
        this.props.onLayoutChanged(layout);
        this.setState({ currentLayout: layout });
      }
    }

    if (this.props.onOrientationChanged) {
      const orientation = this.getOrientationType();
      if (orientation !== this.state.currentOrientation) {
        this.props.onOrientationChanged(orientation);
        this.setState({ currentOrientation: orientation });
      }
    }

    if (this.props.onWidthChange) {
      const width = window.innerWidth;
      this.props.onWidthChange(width);
    }
  };

  public render(): React.ReactNode {
    return null;
  }
}
