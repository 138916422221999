import { Button, Snackbar, SnackbarContent, Theme, Typography } from '@material-ui/core';
import { WithStyles } from '@material-ui/core/';
import { createStyles, withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { PRIVACY_URL } from '../../../constants';
import { CONSENT_MODE_TYPE } from '../../../data-types/cookies/cookiesEnums';
import { closeConsentPrompts, setCookieConsentDialog, setCookieSnackbar } from '../../../state/actions';
import { TStore } from '../../../state/types';
import { hasAcceptedCookies2021, setConsentMode } from '../../../utils/cookies';

interface Props {
  consentSnackbarOpen: boolean;
}

class CookiesConsentModeSnackbar extends React.Component<Props & WithStyles<typeof styles> & WrappedComponentProps> {
  public componentDidMount = () => {
    if (!hasAcceptedCookies2021()) {
      setCookieSnackbar(true);
    }
  };

  private handleCloseSnackbar = () => {
    closeConsentPrompts();
    setConsentMode({ type: CONSENT_MODE_TYPE.ALL, necessary: true, marketing: true, analytics: true, preferences: true });
  };

  private showCookiesModal = () => {
    setCookieSnackbar(false);
    setCookieConsentDialog(true);
  };

  private privacyPolicyRedirect = () => {
    window.open(PRIVACY_URL, '_blank');
  };

  public render(): React.ReactNode {
    const { classes, consentSnackbarOpen } = this.props;

    return (
      <Snackbar open={consentSnackbarOpen} className={classes.snackBar}>
        <SnackbarContent
          classes={{
            root: classes.contentRoot,
          }}
          message={
            <React.Fragment>
              <Typography variant="caption" className={classes.snackbarText}>
                <FormattedMessage id="app_container.website_uses_cookies" />
              </Typography>
              &nbsp;
              <Typography variant="caption" className={classes.snackBarLinkText} onClick={this.privacyPolicyRedirect}>
                <FormattedMessage id="app_container.click_to_learn_more" />
              </Typography>
            </React.Fragment>
          }
          action={
            <div>
              <Button
                className={`${classes.snackbarButton} consent-choice`}
                variant="contained"
                color="primary"
                onClick={this.handleCloseSnackbar}
                classes={{
                  label: 'consent-choice',
                }}
              >
                <FormattedMessage id="cookies.accept_all" />
              </Button>
              <Button
                className={`${classes.snackbarButton} consent-choice`}
                variant="outlined"
                color="primary"
                onClick={this.showCookiesModal}
                classes={{
                  label: 'consent-choice',
                }}
              >
                <FormattedMessage id="cookies.manage_cookies" />
              </Button>
            </div>
          }
        />
      </Snackbar>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    snackBar: {
      justifyContent: 'center',
      margin: theme.spacing(1),
      [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
        width: `calc(100% - ${theme.spacing(1)}px - ${theme.spacing(1)}px)`,
      },
    },
    contentRoot: {
      backgroundColor: theme.palette.grey[200],
    },
    snackbarText: {
      color: theme.palette.common.black,
    },
    snackBarLinkText: {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
    snackbarButton: {
      marginRight: '4px',
      boxShadow: theme.shadows[0],
    },
  });

const mapStateToProps = (store: TStore) => ({
  consentSnackbarOpen: store.consentSnackbarOpen,
});

export default connect(mapStateToProps)(withStyles(styles)(injectIntl(CookiesConsentModeSnackbar)));
