import { compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { handler } from './reducers';

const persistConfig = {
  storage,
  key: 'root',
  whitelist: ['geoLocation'],
};

const persistedReducer = persistReducer(persistConfig, handler);

const isKwiffProduction = window.location.origin.includes('kwiff.com');
const isBetkwiffProduction = window.location.origin.includes('betkwiff.com');
const hideDevToolsReduxStore = isKwiffProduction || isBetkwiffProduction;

export const store = createStore(
  persistedReducer,
  hideDevToolsReduxStore
    ? compose()
    : // @ts-ignore
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

// @ts-ignore
export const persistor = persistStore(store);
