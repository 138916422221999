import { createStyles, Slide, Typography, withStyles, WithStyles } from '@material-ui/core/';
import classnames from 'classnames';
import * as React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { KwiffTheme } from '../../themes/theme';
import { generateLogInUrl } from '../../utils/url';

interface Props {
  theme: KwiffTheme;
  isBetkwiffDomain: boolean;
  width?: number;
}

class LoginButton extends React.Component<Props & WithStyles<typeof styles> & WrappedComponentProps> {
  private handleLogin = () => {
    window.open(generateLogInUrl(), undefined, undefined);
  };

  public render(): React.ReactNode {
    const {
      classes,
      width,
      theme,
      isBetkwiffDomain,
      intl: { formatMessage },
    } = this.props;
    const isVerySmallScreen = width && width <= theme.breakpoints.values.xs;
    const isMobileAndBetkwiffDomain = width && width < theme.breakpoints.values.lg && isBetkwiffDomain;

    return (
      <Slide direction="left" in={true} mountOnEnter={true} unmountOnExit={true} timeout={this.props.theme.transitions.duration.short}>
        <div
          data-testid="login-button"
          id="login"
          className={classnames(classes.button, classes.login)}
          style={{
            width: isVerySmallScreen ? '50px' : isMobileAndBetkwiffDomain ? '75px' : '100px',
          }}
          onClick={this.handleLogin}
        >
          <Typography data-testid="login-button-text" id="login-text" className={classes.buttonText}>
            {formatMessage({ id: 'login_button.login' }).toUpperCase()}
          </Typography>
        </div>
      </Slide>
    );
  }
}

const styles = (theme: KwiffTheme) => {
  return createStyles({
    button: {
      cursor: 'pointer',
      height: '36px',
      boxShadow: theme.shadows[0],
      justifyContent: 'space-evenly',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      verticalAlign: 'middle',
      backgroundColor: theme.palette.common.white,
    },
    buttonText: {
      textAlign: 'center',
      fontSize: '12px',
      fontFamily: 'mulish',
      fontWeight: 550,
      color: theme.palette.common.black,
    },
    login: {
      height: '32px',
      width: '100px',
      padding: `0 ${theme.spacing(0.5)}px`,
      minWidth: 'fit-content',
      [theme.breakpoints.down(theme.breakpoints.values.xs)]: {
        width: '50px',
      },
    },
  });
};

export default withStyles(styles, { withTheme: true })(injectIntl(LoginButton));
