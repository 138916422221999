import { Theme, Typography } from '@material-ui/core';
import { WithStyles } from '@material-ui/core/';
import { createStyles, withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import * as React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { BETTING_RULES_URL, TERMS_URL } from '../../constants';
import Divider from '../utils/Divider';

interface Props {
  isBetkwiffDomain: boolean;
}

class Terms2020 extends React.Component<Props & WithStyles<typeof styles> & WrappedComponentProps> {
  private openPage = (link: string) => () => {
    window.open(link, '_blank');
  };

  private generateSpanElementWithLink = (textId: string, link: string) => {
    const { classes } = this.props;

    return (
      <span data-testid={`tc-${textId}`} className={classes.link} onClick={this.openPage(link)}>
        <FormattedMessage id={textId} />
      </span>
    );
  };

  public render(): React.ReactNode {
    const {
      classes,
      isBetkwiffDomain,
      intl: { formatMessage },
    } = this.props;

    return (
      <>
        <div className={classes.welcomeTerms}>
          <Typography data-testid="tc-welcome-title" className={classes.title} variant="h4">
            <FormattedMessage id="terms_and_conditions.welcome_tc" />
          </Typography>
          <Typography
            className={classes.point}
            data-testid="tc-welcome-intro"
            dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'terms_and_conditions.welcome_tc.intro' }) }}
          />
          <Typography data-testid="tc-welcome-one" className={classes.point}>
            <FormattedMessage id="terms_and_conditions.welcome_tc.one" />
          </Typography>
          <Typography
            className={classes.point}
            data-testid="tc-welcome-two"
            dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'terms_and_conditions.welcome_tc.two' }) }}
          />
          <span className={classes.point}>
            <Typography data-testid="tc-welcome-three">
              <FormattedMessage id="terms_and_conditions.welcome_tc.three" />
            </Typography>
            <Typography data-testid="tc-welcome-three-first">
              <FormattedMessage id="terms_and_conditions.welcome_tc.three.first" />
            </Typography>
            <Typography data-testid="tc-welcome-three-second">
              <FormattedMessage id="terms_and_conditions.welcome_tc.three.second" />
            </Typography>
            {!isBetkwiffDomain && (
              <Typography data-testid="tc-welcome-three-third">
                <FormattedMessage id="terms_and_conditions.welcome_tc.three.third" />
              </Typography>
            )}
          </span>
          <Typography data-testid="tc-welcome-four" className={classes.point}>
            <FormattedMessage id="terms_and_conditions.welcome_tc.four" />
          </Typography>
          <Typography data-testid="tc-welcome-five" className={classes.point}>
            <FormattedMessage id="terms_and_conditions.welcome_tc.five" />
          </Typography>
          <Typography data-testid="tc-welcome-six" className={classes.point}>
            <FormattedMessage id="terms_and_conditions.welcome_tc.six" />
          </Typography>
          <Typography data-testid="tc-welcome-seven" className={classes.point}>
            <FormattedMessage id="terms_and_conditions.welcome_tc.seven" />
          </Typography>
          <Typography data-testid="tc-welcome-eight" className={classes.point}>
            <FormattedMessage id="terms_and_conditions.welcome_tc.eight" />
          </Typography>
          <Typography data-testid="tc-welcome-nine" className={classes.point}>
            <FormattedMessage id="terms_and_conditions.welcome_tc.nine" />
          </Typography>
          <Typography data-testid="tc-welcome-ten" className={classes.point}>
            <FormattedMessage id="terms_and_conditions.welcome_tc.ten" />
          </Typography>
          <Typography data-testid="tc-welcome-eleven" className={classes.point}>
            <FormattedMessage id="terms_and_conditions.welcome_tc.eleven" />
          </Typography>
        </div>

        <Divider width="100%" isBetkwiffDomain={isBetkwiffDomain} />
        <div>
          <Typography data-testid="tc-general-title" className={classes.title} variant="h4">
            <FormattedMessage id="terms_and_conditions.general_tc" />
          </Typography>
          <Typography data-testid="tc-general-one" className={classes.point}>
            <FormattedMessage id="terms_and_conditions.general_tc.one" />
          </Typography>
          <Typography data-testid="tc-general-two" className={classes.point}>
            <FormattedMessage id="terms_and_conditions.general_tc.two" />
          </Typography>
          <Typography data-testid="tc-general-three" className={classes.point}>
            <FormattedMessage id="terms_and_conditions.general_tc.three" />
          </Typography>
          <Typography data-testid="tc-general-four" className={classes.point}>
            <FormattedMessage id="terms_and_conditions.general_tc.four" />
          </Typography>
          <Typography data-testid="tc-general-five" className={classes.point}>
            <FormattedMessage id="terms_and_conditions.general_tc.five" />
          </Typography>
          <Typography data-testid="tc-general-six" className={classes.point}>
            <FormattedMessage id="terms_and_conditions.general_tc.six" />
          </Typography>
          <Typography data-testid="tc-general-seven" className={classes.point}>
            <FormattedMessage id="terms_and_conditions.general_tc.seven" />
          </Typography>
          <Typography data-testid="tc-general-eight" className={classes.point}>
            <FormattedMessage id="terms_and_conditions.general_tc.eight" />
          </Typography>
          <Typography data-testid="tc-general-nine" className={classes.point}>
            <FormattedMessage id="terms_and_conditions.general_tc.nine" />
          </Typography>
          <Typography data-testid="tc-general-ten" className={classes.point}>
            <FormattedMessage id="terms_and_conditions.general_tc.ten" />
          </Typography>
          <Typography data-testid="tc-general-eleven" className={classes.point}>
            <FormattedMessage
              id="terms_and_conditions.general_tc.eleven"
              values={{
                tAndCElement: this.generateSpanElementWithLink('terms.t_and_c', TERMS_URL),
                bettingRulesElement: this.generateSpanElementWithLink('terms.betting_rules', BETTING_RULES_URL),
              }}
            />
          </Typography>
          <Typography data-testid="tc-last-update" className={classnames(classes.point, classes.lastUpdate)}>
            <FormattedMessage id="terms_and_conditions.last_update" />
          </Typography>
        </div>
      </>
    );
  }
}

const styles = (theme: Theme) => {
  return createStyles({
    welcomeTerms: {
      marginBottom: theme.spacing(5),
    },
    title: {
      textAlign: 'center',
      fontWeight: 'bold',
      margin: `${theme.spacing(5)}px 0`,
    },
    point: {
      padding: `${theme.spacing(0.6)}px 0`,
      margin: `${theme.spacing(2)}px 0`,
    },
    lastUpdate: {
      margin: `${theme.spacing(5)}px 0`,
      textAlign: 'end',
    },
    link: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  });
};

export default withStyles(styles)(injectIntl(Terms2020));
