import { createStyles, withStyles, WithStyles } from '@material-ui/core';
import * as React from 'react';
import FacebookPng from '../../assets/socials/Facebook.png';
import InstagramPng from '../../assets/socials/Instagram.png';
import TwitterPng from '../../assets/socials/Twitter.png';
import YoutubePng from '../../assets/socials/Youtube.png';
import { FACEBOOK_URL, INSTAGRAM_URL, TWITTER_URL, YOUTUBE_URL } from '../../constants';
import { KwiffTheme } from '../../themes/theme';

class Socials2021 extends React.Component<WithStyles<typeof styles>> {
  private openFacebookUrl = () => {
    window.open(FACEBOOK_URL);
  };

  private openTwitterUrl = () => {
    window.open(TWITTER_URL);
  };

  private openInstagramUrl = () => {
    window.open(INSTAGRAM_URL);
  };

  private openYoutubeUrl = () => {
    window.open(YOUTUBE_URL);
  };

  public render(): React.ReactNode {
    const { classes } = this.props;

    return (
      <div data-testid="socials" className={classes.root}>
        <img data-testid="socials-facebook" className={classes.icon} src={FacebookPng} onClick={this.openFacebookUrl} alt="" />
        <img data-testid="socials-twitter" className={classes.icon} src={TwitterPng} onClick={this.openTwitterUrl} alt="" />
        <img data-testid="socials-instagram" className={classes.icon} src={InstagramPng} onClick={this.openInstagramUrl} alt="" />
        <img data-testid="socials-youtube" className={classes.icon} src={YoutubePng} onClick={this.openYoutubeUrl} alt="" />
      </div>
    );
  }
}
/* Component Styling */
const styles = (theme: KwiffTheme) => {
  return createStyles({
    root: {
      width: '100%',
      display: 'grid',
      justifyContent: 'right',
      alignItems: 'center',
      gridTemplateColumns: 'auto auto auto auto',
      gridColumnGap: theme.spacing(1),
      position: 'absolute',
      right: '5%',
      bottom: '30%',
      [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
        position: 'relative',
        justifyContent: 'center',
        right: 'unset',
        bottom: 'unset',
      },
    },
    icon: {
      width: '50px',
      height: '50px',
      cursor: 'pointer',
    },
  });
};
export default withStyles(styles, { withTheme: true })(Socials2021);
