import { createStyles, withStyles, WithStyles } from '@material-ui/core';
import { Slide, Typography } from '@material-ui/core/';
import classnames from 'classnames';
import * as React from 'react';
import { default as ReactDiv100vh } from 'react-div-100vh';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import topBanner2020Png from '../../assets/2020/topBanner2020.png';
import topBannerCasinoDesktop2020Png from '../../assets/2020/topBannerCasinoDesktop2020.png';
import topBannerCasinoMobile2020Png from '../../assets/2020/topBannerCasinoMobile2020.png';
import kwiffLogoSvg from '../../assets/kwiffLogo.svg';
import JourneyInformation2020 from '../../components/2020/JourneyInformation2020';
import KwiffDetails2020 from '../../components/2020/KwiffDetails2020';
import Partners2020 from '../../components/2020/Partners2020';
import Socials2020 from '../../components/2020/Socials2020';
import SurpriseBetInformation2020 from '../../components/2020/SurpriseBetInformation2020';
import Terms2020 from '../../components/2020/Terms2020';
import CookiesConsentSnackbar from '../../components/snackbars/2020/CookiesConsentSnackbar';
import TopBar from '../../components/TopBar';
import Divider from '../../components/utils/Divider';
import { Layout, ResizeHandler } from '../../components/utils/ResizeHandler';
import { BETKWIFF_PROD, GAMBLING_COMMISSION_LICENCE_URL, GOVERNMENT_URL } from '../../constants';
import { CookieType } from '../../data-types/cookies/cookiesEnums';
import { KwiffTheme, MAX_MEDIUM_WIDTH } from '../../themes/theme';
import { getCookie, handleUserBTagUsage, setCookie } from '../../utils/cookies';

interface Props {
  theme: KwiffTheme;
  isCasinoDomain: boolean;
  isBetkwiffDomain: boolean;
}

interface State {
  layout: Layout;
  cookiesConsentOpen: boolean;
}

class AffiliateLandingPage2020 extends React.Component<Props & WithStyles<typeof styles> & WrappedComponentProps, State> {
  private ref: React.RefObject<HTMLDivElement> = React.createRef();

  public state: State = {
    layout: Layout.MOBILE,
    cookiesConsentOpen: getCookie(CookieType.AFFILIATE_COOKIE_ACCEPTANCE) !== '1',
  };

  public componentDidMount = () => {
    handleUserBTagUsage();
  };

  private openLicenceUrl = () => {
    const { isBetkwiffDomain } = this.props;

    const url = isBetkwiffDomain ? GOVERNMENT_URL : GAMBLING_COMMISSION_LICENCE_URL;
    window.open(url, '_blank');
  };

  private openBetkwiff = () => {
    window.open(BETKWIFF_PROD, '_blank');
  };

  private closeConsentDialog = (accept: boolean) => {
    setCookie('affiliateCookieAcceptance', accept ? '1' : '0');
    this.setState({ cookiesConsentOpen: false });
  };

  private scrollToTermsAndConditions = () => {
    if (this.ref.current) {
      this.ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  private setLayout = (layout: Layout) => {
    this.setState({ layout });
  };

  public render(): React.ReactNode {
    const {
      classes,
      isCasinoDomain,
      isBetkwiffDomain,
      intl: { formatMessage },
    } = this.props;
    const { layout, cookiesConsentOpen } = this.state;

    return (
      <Slide direction="left" in={true} mountOnEnter={true} unmountOnExit={true} timeout={this.props.theme.transitions.duration.short}>
        <ReactDiv100vh className={classes.root}>
          <div data-testid="affiliate-landing-page" className={classes.nonScrollable}>
            <TopBar isBetkwiffDomain={isBetkwiffDomain} />
            <div data-testid="affiliate-landing-page-container" className={classes.container}>
              {layout === Layout.MOBILE ? (
                <div className={classes.grid}>
                  <div
                    data-testid="affiliate-landing-page-background-image-mobile"
                    className={classnames(classes.backgroundedImage, { [classes.casinoBackground]: isCasinoDomain })}
                  />
                  <SurpriseBetInformation2020
                    isCasinoDomain={isCasinoDomain}
                    isBetkwiffDomain={isBetkwiffDomain}
                    onScrollToTermsAndConditions={this.scrollToTermsAndConditions}
                  />
                </div>
              ) : (
                <div className={classnames(classes.grid, classes.desktopView)}>
                  <div
                    data-testid="affiliate-landing-page-background-image-desktop"
                    className={classnames(classes.backgroundedImage, { [classes.casinoBackground]: isCasinoDomain })}
                  />
                  <div className={classes.overText}>
                    <SurpriseBetInformation2020
                      isCasinoDomain={isCasinoDomain}
                      isBetkwiffDomain={isBetkwiffDomain}
                      onScrollToTermsAndConditions={this.scrollToTermsAndConditions}
                    />
                  </div>
                </div>
              )}
              <JourneyInformation2020 isCasinoDomain={isCasinoDomain} />
              <Divider isBetkwiffDomain={isBetkwiffDomain} />
              <div className={classes.termsContainer}>
                <div ref={this.ref} />
                <Terms2020 isBetkwiffDomain={isBetkwiffDomain} />
              </div>
              <Divider isBetkwiffDomain={isBetkwiffDomain} />
              <div className={classes.betkwiffPresentation}>
                <div className={classes.logoContainer} onClick={this.openBetkwiff}>
                  <img className={classes.icon} src={kwiffLogoSvg} alt="" />
                  <Typography className={classes.logo}>
                    <FormattedMessage id={isBetkwiffDomain ? 'common.betkwiff.logo' : 'common.logo'} />
                  </Typography>
                </div>
                <div>
                  <KwiffDetails2020 isBetkwiffDomain={isBetkwiffDomain} />
                  <Divider isBetkwiffDomain={isBetkwiffDomain} />
                  <Typography
                    data-testid="affiliate-landing-page-licence"
                    onClick={this.openLicenceUrl}
                    className={classes.content}
                    variant="body1"
                    dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'responsible_gaming_banner.body' }) }}
                  />
                </div>
              </div>
              <Divider isBetkwiffDomain={isBetkwiffDomain} />
              <Partners2020 isBetkwiffDomain={isBetkwiffDomain} />
              <Divider isBetkwiffDomain={isBetkwiffDomain} />
              <Typography data-testid="affiliate-landing-page-copyright" className={classes.copyright}>
                <FormattedMessage id="copyright" />
              </Typography>
              <Socials2020 />
              {cookiesConsentOpen && <CookiesConsentSnackbar onClose={this.closeConsentDialog} />}
            </div>
            <ResizeHandler onLayoutChanged={this.setLayout} />
          </div>
        </ReactDiv100vh>
      </Slide>
    );
  }
}

const styles = (theme: KwiffTheme) => {
  return createStyles({
    root: {
      overflow: 'hidden',
    },
    nonScrollable: {
      width: '100%',
      height: 'inherit',
      overflow: 'hidden',
      cursor: 'default',
    },
    container: {
      display: 'grid',
      width: '100%',
      height: 'calc(100% - 56px)', // 56px is the height of the header
      backgroundColor: theme.custom && theme.custom.darkGray,
      overflowY: 'scroll',
      overflowX: 'hidden',
    },
    banner: {
      width: '100%',
    },
    content: {
      fontSize: '11px',
      color: theme.palette.common.white,
      textAlign: 'center',
      justifySelf: 'center',
      maxWidth: '90%',
      cursor: 'pointer',
      margin: `${theme.spacing(5)}px auto`,
      [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
        fontSize: '16px',
      },
      [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
        fontSize: '16px',
        margin: '0 auto',
      },
    },
    copyright: {
      fontSize: '11px',
      color: theme.palette.common.white,
      textAlign: 'center',
      margin: `${theme.spacing(2.5)}px 0`,
      [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
        fontSize: '16px',
      },
    },
    backgroundedImage: {
      width: '100vw',
      height: 'calc(100vh - 220px)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundImage: `url(${topBanner2020Png})`,
      [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
        height: 'calc(100vh - 280px)',
      },
      [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
        backgroundPosition: 'left',
        height: '60vh',
      },
      [theme.breakpoints.up(MAX_MEDIUM_WIDTH)]: {
        backgroundPosition: 'left',
        height: '80vh',
      },
      [theme.breakpoints.up(theme.breakpoints.values.xl)]: {
        backgroundPosition: 'left',
        height: 'calc(100vh - 56px)',
      },
    },
    casinoBackground: {
      backgroundPosition: 'center',
      backgroundImage: `url(${topBannerCasinoDesktop2020Png})`,
      [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
        height: '50vh',
        backgroundImage: `url(${topBannerCasinoMobile2020Png})`,
      },
    },
    grid: {
      display: 'grid',
    },
    desktopView: {
      position: 'relative',
    },
    overText: {
      position: 'absolute',
      left: '7%',
      top: '20%',
      [theme.breakpoints.up(MAX_MEDIUM_WIDTH)]: {
        top: '30%',
      },
    },
    termsContainer: {
      maxWidth: '90%',
      justifySelf: 'center',
      color: theme.palette.common.white,
    },
    betkwiffPresentation: {
      width: '90%',
      display: 'grid',
      gridTemplateColumns: '1fr 2fr',
      justifySelf: 'center',
      margin: `${theme.spacing(5)}px 0`,
      [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
        width: '100%',
        gridTemplateColumns: '1fr',
        margin: 0,
      },
    },
    logoContainer: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      gridColumnGap: theme.spacing(1),
      color: theme.palette.common.white,
      alignItems: 'center',
      [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
        display: 'none',
      },
    },
    icon: {
      height: '65px',
      width: '65px',
      justifySelf: 'end',
      cursor: 'pointer',
      [theme.breakpoints.up(MAX_MEDIUM_WIDTH)]: {
        height: '80px',
        width: '80px',
      },
      [theme.breakpoints.up(theme.breakpoints.values.xl)]: {
        height: '100px',
        width: '100px',
      },
    },
    logo: {
      display: 'block',
      fontSize: '60px',
      cursor: 'pointer',
      [theme.breakpoints.up(MAX_MEDIUM_WIDTH)]: {
        fontSize: '75px',
      },
      [theme.breakpoints.up(theme.breakpoints.values.xl)]: {
        fontSize: '95px',
      },
    },
  });
};

export default withStyles(styles, { withTheme: true })(injectIntl(AffiliateLandingPage2020));
