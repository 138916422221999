import { MuiThemeProvider } from '@material-ui/core/styles';
import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import MetaInfo from './components/MetaInfo';
import AffiliateLandingPage2020 from './containers/2020/AffiliateLandingPage2020';
import AffiliateLandingPage2021 from './containers/2021/AffiliateLandingPage2021';
import { CookieType } from './data-types/cookies/cookiesEnums';
import { AcceptedCountries } from './enums';
import { default as en2020Json } from './i18n/2020/en.json';
import { default as en_GB2020Json } from './i18n/2020/en_GB.json';
import { default as de2021Json } from './i18n/2021/de.json';
import { default as en2021Json } from './i18n/2021/en.json';
import { default as en_CA2021Json } from './i18n/2021/en_CA.json';
import { default as en_GB2021Json } from './i18n/2021/en_GB.json';
import { default as en_IE2021Json } from './i18n/2021/en_IE.json';
import { default as en_IN2021Json } from './i18n/2021/en_IN.json';
import { default as en_NZ2021Json } from './i18n/2021/en_NZ.json';
import { default as es2021Json } from './i18n/2021/es.json';
import { default as fi2021Json } from './i18n/2021/fi.json';
import { default as pt_BR2021Json } from './i18n/2021/pt_BR.json';
import { TStore } from './state/types';
import theme from './themes/theme';
import { availablePaths, PATHS } from './utils/appPaths';
import browserHistory from './utils/browserHistory';

interface Props {
  geoLocation: string;
  isCasinoDomain: boolean;
  isBetkwiffDomain: boolean;
}

class App extends React.Component<Props> {
  public componentDidMount = () => {
    this.getSearchParams();
  };

  private getSearchParams = () => {
    const pathname = window.location.pathname;
    const searchParams = new URLSearchParams(window.location.search);
    const btag = searchParams.get(CookieType.BTAG);
    const affid = searchParams.get('affid') || undefined;
    const source = searchParams.get('source') || undefined;

    if (!availablePaths.includes(pathname) && btag) {
      browserHistory.push(PATHS.ROOT_PARAM(btag, affid, source));
    }

    if (!availablePaths.includes(pathname) && !btag) {
      browserHistory.push(PATHS.ROOT);
    }
  };

  private get2021MessageFile = () => {
    const { geoLocation } = this.props;

    switch (geoLocation) {
      case AcceptedCountries.UNITED_KINGDOM:
        return en_GB2021Json;
      case AcceptedCountries.IRELAND:
        return en_IE2021Json;
      case AcceptedCountries.NEW_ZEALAND:
        return en_NZ2021Json;
      case AcceptedCountries.CANADA:
        return en_CA2021Json;
      case AcceptedCountries.INDIA:
        return en_IN2021Json;
      case AcceptedCountries.GERMANY:
        return de2021Json;
      case AcceptedCountries.BRAZIL:
        return pt_BR2021Json;
      case AcceptedCountries.FINLAND:
        return fi2021Json;
      case AcceptedCountries.MEXICO:
      case AcceptedCountries.COLOMBIA:
      case AcceptedCountries.CHILE:
      case AcceptedCountries.PERU:
      case AcceptedCountries.ARGENTINA:
      case AcceptedCountries.ECUADOR:
      case AcceptedCountries.EL_SALVADOR:
      case AcceptedCountries.PARAGUAY:
      case AcceptedCountries.URUGUAY:
        return es2021Json;
      default:
        return en2021Json;
    }
  };

  private getMessagesFile = () => {
    const { isBetkwiffDomain } = this.props;

    switch (window.location.pathname) {
      case PATHS.OFFER.YEAR_2020:
        return isBetkwiffDomain ? en2020Json : en_GB2020Json;
      default:
        return this.get2021MessageFile();
    }
  };

  private render2020Version = () => {
    const { isBetkwiffDomain, isCasinoDomain } = this.props;

    return <AffiliateLandingPage2020 isCasinoDomain={isCasinoDomain} isBetkwiffDomain={isBetkwiffDomain} />;
  };

  private render2021Version = () => {
    const { isBetkwiffDomain, isCasinoDomain } = this.props;

    return <AffiliateLandingPage2021 isCasinoDomain={isCasinoDomain} isBetkwiffDomain={isBetkwiffDomain} />;
  };

  public render(): React.ReactNode {
    const messagesFile = this.getMessagesFile();

    return (
      <IntlProvider locale="en" messages={messagesFile}>
        <MetaInfo />
        <MuiThemeProvider theme={theme()}>
          <Router history={browserHistory}>
            <Switch>
              <Route path={PATHS.OFFER.YEAR_2020_OPTIONAL_ARG} exact={true} component={this.render2020Version} />
              <Route path={PATHS.ROOT_OPTIONAL_ARG} exact={true} component={this.render2021Version} />
            </Switch>
          </Router>
        </MuiThemeProvider>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state: TStore) => {
  return {
    geoLocation: state.geoLocation,
    isCasinoDomain: state.isCasinoDomain,
    isBetkwiffDomain: state.isBetkwiffDomain,
  };
};

export default connect(mapStateToProps)(App);
