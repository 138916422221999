import { AcceptedCountries } from '../enums';
import {
  Action,
  CLOSE_CONSENT_PROMPTS,
  SET_CONSENT_DIALOG_OPEN,
  SET_CONSENT_SNACKBAR_OPEN,
  SET_IS_BETKWIFF_DOMAIN,
  SET_IS_CASINO_DOMAIN,
  SET_USER_GEO_LOCATION,
  SET_WEBAPP_ORIGIN,
  TStore,
} from './types';

export const initialState: TStore = {
  geoLocation: AcceptedCountries.UNITED_KINGDOM,
  isCasinoDomain: false,
  isBetkwiffDomain: false,
  webAppOrigin: '',
  consentSnackbarOpen: false,
  consentDialogOpen: false,
};

export const handler = (state = initialState, action: Action): TStore => {
  switch (action.type) {
    case SET_USER_GEO_LOCATION: {
      return { ...state, geoLocation: action.meta };
    }
    case SET_IS_CASINO_DOMAIN: {
      return { ...state, isCasinoDomain: action.meta };
    }
    case SET_IS_BETKWIFF_DOMAIN: {
      return { ...state, isBetkwiffDomain: action.meta };
    }
    case SET_WEBAPP_ORIGIN: {
      return { ...state, webAppOrigin: action.meta };
    }
    case SET_CONSENT_SNACKBAR_OPEN: {
      return { ...state, consentSnackbarOpen: action.meta };
    }
    case SET_CONSENT_DIALOG_OPEN: {
      return { ...state, consentDialogOpen: action.meta };
    }
    case CLOSE_CONSENT_PROMPTS: {
      return { ...state, consentSnackbarOpen: false, consentDialogOpen: false };
    }
    default:
      return state;
  }
};
