import { withTheme } from '@material-ui/core';
import * as React from 'react';
import { KwiffTheme } from '../../themes/theme';

interface Props {
  theme: KwiffTheme;
  width?: string;
  isBetkwiffDomain: boolean;
}

const divider: React.FunctionComponent<Props> = ({ theme, width, isBetkwiffDomain }) => {
  return (
    <div
      data-testid="divider"
      style={{
        width: width ? width : isBetkwiffDomain && window.innerWidth > 1024 ? '95%' : '90%',
        height: '0.5px',
        margin: `${theme.spacing(2)}px auto`,
        backgroundColor: theme.palette.grey[800],
      }}
    />
  );
};

// tslint:disable-next-line:variable-name
const Divider = withTheme(divider);
export default Divider;
