import { AutoHideBehavior, OverflowBehavior } from 'overlayscrollbars';

export interface OverlayScrollbarsComponentOptions {
  scrollbars?: {
    autoHide?: AutoHideBehavior;
    autoHideDelay?: number;
  };
  overflowBehavior?: {
    x?: OverflowBehavior;
    y?: OverflowBehavior;
  };
}

export const defaultScrollbarsOptions: OverlayScrollbarsComponentOptions = { scrollbars: { autoHide: 'leave', autoHideDelay: 0 } };

export const noHorizontalScrolling: OverlayScrollbarsComponentOptions = { overflowBehavior: { x: 'hidden' } };
