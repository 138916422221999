import { createStyles, withStyles, WithStyles } from '@material-ui/core';
import classnames from 'classnames';
import * as React from 'react';
import governmentLogoPng from '../../assets/governmentLogo.png';
import BeGambleAwarePng from '../../assets/partners/BeGambleAware.png';
import EcograPng from '../../assets/partners/Ecogra.png';
import EvoGamingPng from '../../assets/partners/EvoGaming.png';
import GamblingCommissionPng from '../../assets/partners/Gambling-Commission.png';
import GamCareSvg from '../../assets/partners/GamCare.svg';
import GamStopPng from '../../assets/partners/GamStop.png';
import MasterCardPng from '../../assets/partners/MasterCard.png';
import MicroGamingPng from '../../assets/partners/MicroGaming.png';
import NetentPng from '../../assets/partners/Netent.png';
import PlayNGoPng from '../../assets/partners/PlayNGo.png';
import SafechargePng from '../../assets/partners/Safecharge.png';
import VisaPng from '../../assets/partners/Visa.png';
import YggdrasilPng from '../../assets/partners/Yggdrasil.png';
import UnderAge18Svg from '../../assets/UnderAge18.svg';
import {
  ECOGRA_URL,
  EVOLUTION_URL,
  GAMBLE_AWARE_URL,
  GAMBLING_COMMISSION_LICENCE_URL,
  GAMCARE_URL,
  GAMSTOP_URL,
  GOVERNMENT_URL,
  MASTERCARD_URL,
  MICROGAMING_URL,
  NETENT_URL,
  PLAYNGO_URL,
  SAFECHARGE_URL,
  VISA_URL,
  YGGDRASIL_URL,
} from '../../constants';
import { KwiffTheme } from '../../themes/theme';
import { Layout, ResizeHandler } from '../utils/ResizeHandler';

interface Props {
  isBetkwiffDomain: boolean;
}

interface State {
  layout: Layout;
}

class Partners2021 extends React.Component<Props & WithStyles<typeof styles>, State> {
  public state: State = {
    layout: Layout.MOBILE,
  };

  private setLayout = (layout: Layout) => {
    this.setState({ layout });
  };

  private openGamblingCommission = () => {
    window.open(GAMBLING_COMMISSION_LICENCE_URL);
  };

  private openGamCare = () => {
    window.open(GAMCARE_URL);
  };

  private openGamStop = () => {
    window.open(GAMSTOP_URL);
  };

  private openVisa = () => {
    window.open(VISA_URL);
  };

  private openMastercard = () => {
    window.open(MASTERCARD_URL);
  };
  private openMicroGaming = () => {
    window.open(MICROGAMING_URL);
  };

  private openEcogra = () => {
    window.open(ECOGRA_URL);
  };

  private openGambleAware = () => {
    window.open(GAMBLE_AWARE_URL);
  };

  private openEvoGaming = () => {
    window.open(EVOLUTION_URL);
  };

  private openNetent = () => {
    window.open(NETENT_URL);
  };

  private openPlayNGo = () => {
    window.open(PLAYNGO_URL);
  };

  private openSafeCharge = () => {
    window.open(SAFECHARGE_URL);
  };

  private openYggdrasil = () => {
    window.open(YGGDRASIL_URL);
  };

  private openGovernmentLink = () => {
    window.open(GOVERNMENT_URL);
  };

  private renderDesktopLayout = () => {
    const { classes } = this.props;

    return (
      <div className={classes.content}>
        <div className={classes.row}>
          <img
            data-testid="partners-gambling-commission"
            className={classes.gamblingCommission}
            src={GamblingCommissionPng}
            onClick={this.openGamblingCommission}
            alt=""
          />
          <img data-testid="partners-gamcare" className={classes.gamCare} src={GamCareSvg} onClick={this.openGamCare} alt="" />
          <img data-testid="partners-gamstop" className={classes.gamStop} src={GamStopPng} onClick={this.openGamStop} alt="" />
          <img
            data-testid="partners-gamble-aware"
            className={classes.gambleAware}
            src={BeGambleAwarePng}
            onClick={this.openGambleAware}
            alt=""
          />
          <img data-testid="partners-under-age" className={classes.underAge} src={UnderAge18Svg} alt="" />
          <img data-testid="partners-visa" className={classes.visa} src={VisaPng} onClick={this.openVisa} alt="" />
          <img data-testid="partners-mastercard" className={classes.mastercard} src={MasterCardPng} onClick={this.openMastercard} alt="" />
          <img data-testid="partners-ecogra" className={classes.ecogra} src={EcograPng} onClick={this.openEcogra} alt="" />
          <img data-testid="partners-safecharge" className={classes.safecharge} src={SafechargePng} onClick={this.openSafeCharge} alt="" />
        </div>

        <div className={classnames(classes.row, classes.smallerRow)}>
          <img data-testid="partners-evo-gaming" className={classes.evoGaming} src={EvoGamingPng} onClick={this.openEvoGaming} alt="" />

          <img data-testid="partners-netent" className={classes.netent} src={NetentPng} onClick={this.openNetent} alt="" />
          <img data-testid="partners-playngo" className={classes.playngo} src={PlayNGoPng} onClick={this.openPlayNGo} alt="" />
          <img data-testid="partners-yggdrasil" className={classes.yggdrasil} src={YggdrasilPng} onClick={this.openYggdrasil} alt="" />
          <img
            data-testid="partners-microgaming"
            className={classes.microGaming}
            src={MicroGamingPng}
            onClick={this.openMicroGaming}
            alt=""
          />
        </div>
      </div>
    );
  };

  private renderMobileLayout = () => {
    const { classes } = this.props;

    return (
      <div className={classes.content}>
        <div className={classes.row}>
          <img
            data-testid="partners-gambling-commission"
            className={classes.gamblingCommission}
            src={GamblingCommissionPng}
            onClick={this.openGamblingCommission}
            alt=""
          />
          <img data-testid="partners-gamcare" className={classes.gamCare} src={GamCareSvg} onClick={this.openGamCare} alt="" />
          <img data-testid="partners-gamstop" className={classes.gamStop} src={GamStopPng} onClick={this.openGamStop} alt="" />
        </div>
        <div className={classes.row}>
          <img data-testid="partners-under-age" className={classes.underAge} src={UnderAge18Svg} alt="" />
          <img data-testid="partners-visa" className={classes.visa} src={VisaPng} onClick={this.openVisa} alt="" />
          <img data-testid="partners-mastercard" className={classes.mastercard} src={MasterCardPng} onClick={this.openMastercard} alt="" />
        </div>
        <div className={classnames(classes.row, classes.twoColumnsRow, classes.smallHeight)}>
          <img
            data-testid="partners-gamble-aware"
            className={classes.gambleAware}
            src={BeGambleAwarePng}
            onClick={this.openGambleAware}
            alt=""
          />
          <img data-testid="partners-evo-gaming" className={classes.evoGaming} src={EvoGamingPng} onClick={this.openEvoGaming} alt="" />
        </div>
        <div className={classnames(classes.row, classes.twoColumnsRow, classes.mediumHeight)}>
          <img data-testid="partners-netent" className={classes.netent} src={NetentPng} onClick={this.openNetent} alt="" />
          <img data-testid="partners-playngo" className={classes.playngo} src={PlayNGoPng} onClick={this.openPlayNGo} alt="" />
        </div>
        <div className={classnames(classes.row, classes.twoColumnsRow)}>
          <img data-testid="partners-safecharge" className={classes.safecharge} src={SafechargePng} onClick={this.openSafeCharge} alt="" />
          <img data-testid="partners-yggdrasil" className={classes.yggdrasil} src={YggdrasilPng} onClick={this.openYggdrasil} alt="" />
        </div>
        <div className={classnames(classes.row, classes.twoColumnsRow)}>
          <img
            data-testid="partners-microgaming"
            className={classes.microGaming}
            src={MicroGamingPng}
            onClick={this.openMicroGaming}
            alt=""
          />
          <img data-testid="partners-ecogra" className={classes.ecogra} src={EcograPng} onClick={this.openEcogra} alt="" />
        </div>
      </div>
    );
  };

  public render(): React.ReactNode {
    const { classes, isBetkwiffDomain } = this.props;
    const { layout } = this.state;
    const isMobile = layout === Layout.MOBILE;

    return (
      <div data-testid="partners" className={classes.root}>
        {isMobile ? this.renderMobileLayout() : this.renderDesktopLayout()}
        {isBetkwiffDomain ? (
          <img
            data-testid="government-logo"
            className={classes.governmentLogo}
            src={governmentLogoPng}
            onClick={this.openGovernmentLink}
            alt=""
          />
        ) : (
          <span />
        )}
        <ResizeHandler onLayoutChanged={this.setLayout} />
      </div>
    );
  }
}

/* Component Styling */
const styles = (theme: KwiffTheme) => {
  return createStyles({
    root: {
      display: 'grid',
      justifyContent: 'center',
      gridTemplateRows: 'auto auto',
    },
    content: {
      display: 'grid',
      justifyContent: 'center',
      gridTemplateRows: '1fr 1fr 30px 50px 50px 1fr',
      [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
        gridTemplateColumns: 'auto',
        gridTemplateRows: '1fr 1fr',
      },
    },
    row: {
      display: 'grid',
      justifyContent: 'center',
      alignItems: 'center',
      height: '75px',
      gridTemplateColumns: '1fr 1fr 1fr',
      gridColumnGap: '12px',
      [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
        gridTemplateColumns: 'repeat(9, 1fr)',
      },
    },
    smallerRow: {
      [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
        gridTemplateColumns: 'repeat(5, 1fr)',
        margin: '0 auto',
      },
    },
    twoColumnsRow: {
      gridTemplateColumns: '1fr 1fr',
    },
    gamblingCommission: {
      height: '25px',
      width: '90px',
      cursor: 'pointer',
      justifySelf: 'center',
    },
    gamCare: {
      height: '85px',
      width: '85px',
      cursor: 'pointer',
      justifySelf: 'center',
    },
    gamStop: {
      height: '25px',
      width: '90px',
      cursor: 'pointer',
      justifySelf: 'center',
    },
    underAge: {
      height: '70px',
      width: '70px',
      justifySelf: 'center',
    },
    visa: {
      height: '25px',
      width: '90px',
      cursor: 'pointer',
      justifySelf: 'center',
    },
    mastercard: {
      height: '20px',
      width: '100px',
      cursor: 'pointer',
      justifySelf: 'center',
    },
    gambleAware: {
      height: '15px',
      width: '110px',
      cursor: 'pointer',
      justifySelf: 'center',
    },
    evoGaming: {
      height: '15px',
      width: '110px',
      cursor: 'pointer',
      justifySelf: 'center',
    },
    netent: {
      height: '65px',
      width: '110px',
      cursor: 'pointer',
      justifySelf: 'center',
    },
    playngo: {
      height: '30px',
      width: '110px',
      cursor: 'pointer',
      justifySelf: 'center',
    },
    safecharge: {
      height: '30px',
      width: '110px',
      cursor: 'pointer',
      justifySelf: 'center',
    },
    yggdrasil: {
      height: '50px',
      width: '150px',
      cursor: 'pointer',
      justifySelf: 'center',
    },
    microGaming: {
      height: '30px',
      width: '110px',
      cursor: 'pointer',
      justifySelf: 'center',
    },
    ecogra: {
      height: '30px',
      width: '110px',
      cursor: 'pointer',
      justifySelf: 'center',
    },
    smallHeight: {
      height: '30px',
    },
    mediumHeight: {
      height: '50px',
    },
    governmentLogo: {
      height: '100px',
      justifySelf: 'center',
      cursor: 'pointer',
    },
  });
};
export default withStyles(styles, { withTheme: true })(Partners2021);
