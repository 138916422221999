import { createStyles, Typography, withStyles, WithStyles } from '@material-ui/core';
import classnames from 'classnames';
import * as React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { KwiffTheme } from '../../themes/theme';
import JoinButton from '../buttons/JoinButton';

interface Props {
  theme: KwiffTheme;
  isCasinoDomain: boolean;
  isBetkwiffDomain: boolean;
  onScrollToTermsAndConditions: () => void;
}

class SurpriseBetInformation2020 extends React.Component<Props & WithStyles<typeof styles> & WrappedComponentProps> {
  private scrollToTermsAndConditions = () => {
    this.props.onScrollToTermsAndConditions();
  };

  private renderSurpriseBet = (currency: string) => {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Typography data-testid="surprise-bet-information-new-customers" className={classes.newCustomers} variant="h6">
          <FormattedMessage id="surprise_bet.new_customers" />
        </Typography>
        <Typography data-testid="surprise-bet-information-bet-one" className={classes.surpriseBetText}>
          <FormattedMessage id="surprise_bet.first_part" values={{ currency, value: 10 }} />
        </Typography>
        <Typography data-testid="surprise-bet-information-bet-two" className={classes.surpriseBetText}>
          <FormattedMessage id="surprise_bet.second_part" />
        </Typography>
      </React.Fragment>
    );
  };

  private renderCasinoSurpriseBet = (currency: string) => {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Typography data-testid="surprise-bet-information-new-customers" className={classes.surpriseBetText} variant="h6">
          <FormattedMessage id="surprise_bet.casino.first_part" />
        </Typography>
        <Typography data-testid="surprise-bet-information-bet-one" className={classes.surpriseBetText}>
          <FormattedMessage id="surprise_bet.casino.second_part" />
        </Typography>
        <Typography data-testid="surprise-bet-information-bet-two" className={classes.surpriseBetText}>
          <FormattedMessage id="surprise_bet.casino.third_part" values={{ currency, value: 10 }} />
        </Typography>
      </React.Fragment>
    );
  };

  public render(): React.ReactNode {
    const {
      classes,
      isCasinoDomain,
      isBetkwiffDomain,
      intl: { formatMessage },
    } = this.props;
    const currency = formatMessage({ id: 'common.currencySymbol' });

    return (
      <div data-testid="surprise-bet-information" className={classes.root}>
        <div className={classes.surpriseBetContent}>
          {isCasinoDomain ? this.renderCasinoSurpriseBet(currency) : this.renderSurpriseBet(currency)}
        </div>
        <div data-testid="surprise-bet-information-signup-button" className={classes.joinButton}>
          <JoinButton renderJoinHere={false} isBetkwiffDomain={isBetkwiffDomain} />
        </div>
        <div className={classes.stakeTextContainer}>
          <Typography data-testid="surprise-bet-information-stake" className={classes.stakeText}>
            <FormattedMessage id="stake_included" />
          </Typography>
          <Typography
            data-testid="surprise-bet-information-terms-apply"
            className={classnames(classes.stakeText, classes.termsText)}
            onClick={this.scrollToTermsAndConditions}
          >
            <FormattedMessage id="terms.apply" />
          </Typography>
        </div>
      </div>
    );
  }
}
/* Component Styling */
const styles = (theme: KwiffTheme) => {
  return createStyles({
    root: {
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
        backgroundColor: 'transparent',
      },
    },
    surpriseBetContent: {
      margin: `${theme.spacing(2)}px 0`,
    },
    surpriseBetText: {
      color: theme.palette.common.white,
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '22px',
      [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
        fontSize: '40px',
      },
    },
    joinButton: {
      display: 'flex',
      justifyContent: 'center',
    },
    stakeTextContainer: {
      display: 'grid',
      justifyContent: 'center',
      gridTemplateColumns: 'auto auto',
      gridColumnGap: '8px',
      width: '100%',
      margin: `${theme.spacing(1)}px 0`,
    },
    stakeText: {
      color: theme.palette.common.white,
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '13px',
    },
    termsText: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    newCustomers: {
      color: theme.palette.common.white,
      textAlign: 'center',
      fontWeight: 'bold',
      marginBottom: theme.spacing(1.5),
    },
  });
};
export default withStyles(styles, { withTheme: true })(injectIntl(SurpriseBetInformation2020));
