export enum CookieType {
  AFFILIATE_COOKIE_ACCEPTANCE = 'affiliateCookieAcceptance',
  BTAG = 'bTag',
  AFF_ID = 'affId',
  SOURCE_ID = 'sourceId',
  SITE_ID = 'siteId',
  A_ID = 'aId',
  BANNER_ID = 'bannerId',
  CONSENT_MODE = 'consentMode',
}

export enum CONSENT_MODE_TYPE {
  ALL,
  NECESSARY,
  CUSTOM,
  AUTO_ALL,
}
