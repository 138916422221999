import { createStyles, withStyles, WithStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core/';
import classnames from 'classnames';
import * as React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { KwiffTheme } from '../../themes/theme';
import Circle from '../utils/Circle';

interface Props {
  theme: KwiffTheme;
  isCasinoDomain: boolean;
}

class JourneyInformation2020 extends React.Component<Props & WithStyles<typeof styles> & WrappedComponentProps> {
  private renderJourneyInformation = (currency: string) => {
    const {
      classes,
      intl: { formatMessage },
    } = this.props;

    return (
      <React.Fragment>
        <Typography data-testid="journey-information-get-first" className={classes.text}>
          {formatMessage({ id: 'get_bet.first' }, { currency, value: 10 }).toUpperCase()}
        </Typography>
        <Typography data-testid="journey-information-get-second" className={classes.text}>
          {formatMessage({ id: 'get_bet.second' }).toUpperCase()}
        </Typography>
        <Typography data-testid="journey-information-get-third" className={classes.text}>
          {formatMessage({ id: 'get_bet.third' }, { value: 30 }).toUpperCase()}
        </Typography>
        <Typography data-testid="journey-information-get-forth" className={classes.text}>
          {formatMessage({ id: 'get_bet.forth' }).toUpperCase()}
        </Typography>
      </React.Fragment>
    );
  };

  private renderCasinoJourneyInformation = (currency: string) => {
    const {
      classes,
      intl: { formatMessage },
    } = this.props;

    return (
      <React.Fragment>
        <Typography data-testid="journey-information-get-first" className={classes.text}>
          {formatMessage({ id: 'get_bet.casino.first' }, { currency, value: 10 }).toUpperCase()}
        </Typography>
        <Typography data-testid="journey-information-get-second" className={classes.text}>
          {formatMessage({ id: 'get_bet.casino.second' }).toUpperCase()}
        </Typography>
        <Typography data-testid="journey-information-get-third" className={classes.text}>
          {formatMessage({ id: 'get_bet.casino.third' }).toUpperCase()}
        </Typography>
        <Typography data-testid="journey-information-get-forth" className={classes.text}>
          {formatMessage({ id: 'get_bet.casino.fourth' }, { currency, value: 30 }).toUpperCase()}
        </Typography>
        <Typography data-testid="journey-information-get-fifth" className={classes.text}>
          {formatMessage({ id: 'get_bet.casino.fifth' }).toUpperCase()}
        </Typography>
      </React.Fragment>
    );
  };

  public render(): React.ReactNode {
    const {
      classes,
      theme,
      isCasinoDomain,
      intl: { formatMessage },
    } = this.props;
    const currency = formatMessage({ id: 'common.currencySymbol' });

    return (
      <div data-testid="journey-information" className={classes.root}>
        <div className={classes.grid}>
          <div data-testid="journey-information-join-container" className={classes.row}>
            <Circle
              color={theme.palette.common.white}
              backgroundColor={theme.palette.primary.main}
              text={formatMessage({ id: 'join' }).toUpperCase()}
            />
            <span className={classes.textContainer}>
              <Typography data-testid="journey-information-join-first" className={classes.text}>
                {formatMessage({ id: 'create_kwiff_account.first' }).toUpperCase()}
              </Typography>
              <Typography data-testid="journey-information-join-second" className={classes.text}>
                {formatMessage({ id: 'create_kwiff_account.second' }).toUpperCase()}
              </Typography>
              <Typography data-testid="journey-information-join-third" className={classes.text}>
                {formatMessage({ id: 'create_kwiff_account.third' }).toUpperCase()}
              </Typography>
            </span>
          </div>
          <div data-testid="journey-information-bet-container" className={classes.row}>
            <Circle
              color={theme.palette.primary.main}
              backgroundColor={theme.palette.common.white}
              text={formatMessage({ id: 'bet' }).toUpperCase()}
            />
            <span className={classes.textContainer}>
              <Typography data-testid="journey-information-bet-first" className={classes.text}>
                {formatMessage({ id: 'place_bet.first' }).toUpperCase()}
              </Typography>
              <Typography data-testid="journey-information-bet-second" className={classes.text}>
                {formatMessage({ id: 'place_bet.second' }).toUpperCase()}
              </Typography>
              <Typography data-testid="journey-information-bet-third" className={classes.text}>
                {formatMessage({ id: 'place_bet.third' }, { currency, value: 20 }).toUpperCase()}
              </Typography>
            </span>
          </div>
          <div data-testid="journey-information-get-container" className={classes.row}>
            <Circle
              color={theme.palette.common.white}
              backgroundColor={(theme.custom && theme.custom.gold) || theme.palette.primary.main}
              text={formatMessage({ id: 'get' }).toUpperCase()}
            />
            <span className={classnames(classes.textContainer, classes.textContainerWithMultipleRows)}>
              {isCasinoDomain ? this.renderCasinoJourneyInformation(currency) : this.renderJourneyInformation(currency)}
            </span>
          </div>
        </div>
      </div>
    );
  }
}
/* Component Styling */
const styles = (theme: KwiffTheme) => {
  return createStyles({
    root: {
      display: 'grid',
      gridTemplateRows: 'auto',
    },
    grid: {
      display: 'grid',
      gridTemplateRows: 'auto auto auto auto',
      gridRowGap: theme.spacing(4),
      marginTop: theme.spacing(5),
      [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
        gridTemplateRows: 'auto auto',
        gridTemplateColumns: '1fr 1fr 1fr',
      },
    },
    textContainer: {
      display: 'grid',
      gridTemplateRows: 'auto auto auto',
      alignContent: 'center',
      justifyItems: 'center',
      minWidth: '150px',
    },
    textContainerWithMultipleRows: {
      gridTemplateRows: 'auto',
    },
    text: {
      fontSize: '16px',
      color: theme.palette.common.white,
    },
    row: {
      display: 'grid',
      gridTemplateColumns: '0.5fr 1fr',
      justifyItems: 'center',
      alignItems: 'center',
      margin: '0 auto',
      gridColumnGap: theme.spacing(4),
      [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
        gridColumnGap: theme.spacing(1),
      },
    },
  });
};
export default withStyles(styles, { withTheme: true })(injectIntl(JourneyInformation2020));
