import { createStyles, withStyles, WithStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core/';
import classnames from 'classnames';
import * as React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { AcceptedCountries } from '../../enums';
import { TStore } from '../../state/types';
import { KwiffTheme, MIN_JOURNEY_CONTAINER_WIDTH } from '../../themes/theme';
import { displayMinimalContent, getCurrencyValue } from '../../utils/i18n';
import Circle from '../utils/Circle';
import { ResizeHandler } from '../utils/ResizeHandler';

interface Props {
  theme: KwiffTheme;
  isCasinoDomain: boolean;
  geoLocation: string;
}

interface State {
  width: number;
}

class JourneyInformation2021 extends React.Component<Props & WithStyles<typeof styles> & WrappedComponentProps, State> {
  public state: State = {
    width: window.innerWidth,
  };

  private setWidth = (width: number) => {
    this.setState({ width });
  };

  private renderJoinInfoMobile = () => {
    const {
      classes,
      isCasinoDomain,
      intl: { formatMessage },
    } = this.props;

    return (
      <span className={classes.textContainer}>
        {isCasinoDomain ? (
          <>
            <Typography data-testid="journey-information-join-first" className={classnames(classes.text, classes.bold)}>
              {formatMessage({ id: 'create_kwiff_account.first.create' }).toUpperCase()}
            </Typography>
            {!displayMinimalContent() && (
              <Typography data-testid="journey-information-join-second-casino" className={classnames(classes.text, classes.smallText)}>
                {formatMessage({ id: 'create_kwiff_account.casino.second' })}
              </Typography>
            )}
          </>
        ) : (
          <>
            <Typography data-testid="journey-information-join-first" className={classnames(classes.text, classes.bold)}>
              {formatMessage({ id: 'create_kwiff_account.first' }).toUpperCase()}
            </Typography>
            <Typography data-testid="journey-information-join-second" className={classnames(classes.text, classes.bold)}>
              {formatMessage({ id: 'create_kwiff_account.second' }).toUpperCase()}
            </Typography>
          </>
        )}
      </span>
    );
  };

  private renderJoinInfoDesktop = () => {
    const {
      classes,
      isCasinoDomain,
      intl: { formatMessage },
    } = this.props;

    return (
      <span className={classes.textContainer}>
        {!isCasinoDomain ? (
          <>
            <Typography data-testid="journey-information-join-first" className={classnames(classes.text, classes.bold)}>
              {formatMessage({ id: 'create_kwiff_account.first' }).toUpperCase()}
            </Typography>
            <Typography data-testid="journey-information-join-second" className={classnames(classes.text, classes.bold)}>
              {formatMessage({ id: 'create_kwiff_account.second' }).toUpperCase()}
            </Typography>
          </>
        ) : (
          <>
            <Typography data-testid="journey-information-join-first" className={classnames(classes.text, classes.bold)}>
              {formatMessage({ id: 'create_kwiff_account.first.create' }).toUpperCase()}
            </Typography>
            {!displayMinimalContent() && (
              <Typography data-testid="journey-information-bet-second" className={classnames(classes.text, classes.smallText)}>
                {formatMessage({ id: 'create_kwiff_account.casino.second' })}
              </Typography>
            )}
          </>
        )}
      </span>
    );
  };

  private getSportPoundsToBet = () => {
    const { geoLocation } = this.props;

    switch (geoLocation) {
      case AcceptedCountries.BRAZIL:
        return 2;
      default:
        return 10;
    }
  };

  private renderBetInfo = (currency: string) => {
    const {
      classes,
      intl: { formatMessage },
    } = this.props;

    return (
      <span className={classes.textContainer}>
        <Typography data-testid="journey-information-bet-first" className={classnames(classes.text, classes.bold)}>
          {formatMessage({ id: 'place_bet.first' }).toUpperCase()}
        </Typography>
        <Typography data-testid="journey-information-bet-second" className={classnames(classes.text, classes.smallText)}>
          {formatMessage({ id: 'place_bet.second' }, { currency, odds: '1.50', value: getCurrencyValue(this.getSportPoundsToBet()) })}
        </Typography>
      </span>
    );
  };

  private getCasinoPoundsToBet = () => {
    const { geoLocation } = this.props;

    switch (geoLocation) {
      case AcceptedCountries.BRAZIL:
      case AcceptedCountries.INDIA:
      case AcceptedCountries.MEXICO:
      case AcceptedCountries.COLOMBIA:
      case AcceptedCountries.CHILE:
      case AcceptedCountries.PERU:
      case AcceptedCountries.ARGENTINA:
      case AcceptedCountries.ECUADOR:
      case AcceptedCountries.EL_SALVADOR:
      case AcceptedCountries.PARAGUAY:
      case AcceptedCountries.URUGUAY:
        return 10;
      case AcceptedCountries.NEW_ZEALAND:
        return 5;
      default:
        return 20;
    }
  };

  private renderBetInfoCasino = (currency: string) => {
    const {
      classes,
      intl: { formatMessage },
    } = this.props;
    const displayMinContent = displayMinimalContent();

    return (
      <span className={classes.textContainer}>
        <Typography data-testid="journey-information-bet-first" className={classnames(classes.text, classes.bold)}>
          {formatMessage({ id: 'place_bet.casino' }, { currency, value: getCurrencyValue(this.getCasinoPoundsToBet()) }).toUpperCase()}
        </Typography>
        {!displayMinContent && (
          <Typography data-testid="journey-information-bet-second" className={classnames(classes.text, classes.smallText)}>
            {formatMessage({ id: 'place_bet.casino.second' }, { amount: 5 })}
          </Typography>
        )}
      </span>
    );
  };

  private getSportPoundsToGet = () => {
    const { geoLocation } = this.props;

    switch (geoLocation) {
      case AcceptedCountries.BRAZIL:
        return 5;
      default:
        return 20;
    }
  };

  private renderGetInfo = (currency: string) => {
    const {
      classes,
      intl: { formatMessage },
    } = this.props;

    return (
      <Typography data-testid="journey-information-get-first" className={classnames(classes.text, classes.bold)}>
        {formatMessage({ id: 'get_bet.first' }, { currency, value: getCurrencyValue(this.getSportPoundsToGet()) }).toUpperCase()}
      </Typography>
    );
  };

  private renderGetInfoCasino = () => {
    const {
      classes,
      intl: { formatMessage },
    } = this.props;
    const displayMinContent = displayMinimalContent();
    const amount = displayMinContent ? undefined : 40;
    const spins = displayMinContent ? 100 : 200;

    return (
      <span className={classes.textContainer}>
        <Typography data-testid="journey-information-get-first" className={classnames(classes.text, classes.bold)}>
          {formatMessage({ id: 'get_bet.casino.first' }, { amount: spins }).toUpperCase()}
        </Typography>
        {amount !== undefined && (
          <Typography data-testid="journey-information-get-second" className={classnames(classes.text, classes.smallText)}>
            {formatMessage({ id: 'get_bet.casino.second' }, { amount })}
          </Typography>
        )}
      </span>
    );
  };

  public render(): React.ReactNode {
    const {
      classes,
      theme,
      isCasinoDomain,
      intl: { formatMessage },
    } = this.props;
    const { width } = this.state;

    const currency = formatMessage({ id: 'common.currencySymbol' });
    const shouldDisplaySmallScreenLayout = width <= MIN_JOURNEY_CONTAINER_WIDTH;

    return (
      <div data-testid="journey-information" className={classes.root}>
        <div className={classes.grid}>
          <div data-testid="journey-information-join-container" className={classes.row}>
            <Circle
              color={theme.palette.common.white}
              backgroundColor={theme.palette.primary.main}
              text={formatMessage({ id: isCasinoDomain ? 'casino.join' : 'sports.join' }).toUpperCase()}
            />
            {shouldDisplaySmallScreenLayout ? this.renderJoinInfoMobile() : this.renderJoinInfoDesktop()}
          </div>
          <div data-testid="journey-information-bet-container" className={classes.row}>
            <Circle
              color={theme.palette.primary.main}
              backgroundColor={theme.palette.common.white}
              text={formatMessage({ id: isCasinoDomain ? 'casino.bet' : 'sports.bet' }).toUpperCase()}
            />
            <span className={classes.textContainer}>
              {isCasinoDomain ? this.renderBetInfoCasino(currency) : this.renderBetInfo(currency)}
            </span>
          </div>
          <div data-testid="journey-information-get-container" className={classes.row}>
            <Circle
              color={theme.palette.common.white}
              backgroundColor={(theme.custom && theme.custom.gold) || theme.palette.primary.main}
              text={formatMessage({ id: isCasinoDomain ? 'casino.get' : 'sports.get' }).toUpperCase()}
            />
            <span className={classnames(classes.textContainer, classes.textContainerWithMultipleRows)}>
              {isCasinoDomain ? this.renderGetInfoCasino() : this.renderGetInfo(currency)}
            </span>
          </div>
        </div>
        <ResizeHandler onWidthChange={this.setWidth} />
      </div>
    );
  }
}

const styles = (theme: KwiffTheme) =>
  createStyles({
    root: {
      display: 'grid',
      gridTemplateRows: 'auto',
    },
    grid: {
      display: 'grid',
      gridTemplateRows: 'auto auto auto auto',
      gridRowGap: theme.spacing(4),
      marginTop: theme.spacing(1.25),
      marginLeft: theme.spacing(0.75),
      marginRight: theme.spacing(0.75),
      justifyContent: 'center',
      [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
        gridTemplateRows: 'auto auto',
        gridTemplateColumns: '1fr 1fr 1fr',
        justifySelf: 'center',
        marginRight: '20px',
        marginTop: theme.spacing(5),
      },
    },
    textContainer: {
      display: 'grid',
      gridTemplateRows: 'auto auto',
      alignContent: 'center',
      justifyItems: 'center',
      minWidth: '150px',
    },
    textContainerWithMultipleRows: {
      gridTemplateRows: 'auto',
    },
    text: {
      fontSize: '18px',
      textAlign: 'center',
      color: theme.palette.common.white,
      paddingRight: theme.spacing(1),
    },
    smallText: {
      fontSize: '14px',
    },
    row: {
      display: 'grid',
      gridTemplateColumns: '120px 1fr',
      justifyItems: 'center',
      alignItems: 'center',
      gridColumnGap: '6px',
      margin: '0 auto',
      [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
        width: 'auto',
      },
      [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
        width: '100%',
      },
      [theme.breakpoints.down(theme.breakpoints.values.xs)]: {
        gridTemplateColumns: '90px 1fr',
      },
    },
    bold: {
      fontWeight: 'bold',
    },
  });

const mapStateToProps = (store: TStore) => ({
  geoLocation: store.geoLocation,
});

export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(injectIntl(JourneyInformation2021)));
