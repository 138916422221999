import { Theme } from '@material-ui/core';
import createMuiTheme, { Theme as TTheme, ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

interface CustomThemeOptions {
  custom?: {
    darkGray: string;
    gold: string;
  };
}

interface KwiffThemeOptions extends ThemeOptions, CustomThemeOptions {}
export interface KwiffTheme extends Theme, CustomThemeOptions {}

const kwiffOptions: KwiffThemeOptions = {
  palette: {
    primary: {
      light: '#9249FF',
      main: '#8225FA',
      dark: '#5D00CD',
    },
    secondary: {
      main: '#C49A4D',
      dark: '#809593',
    },
    grey: {
      900: '#142323',
      800: '#798c8c',
      100: '#efefef',
    },
  },
  custom: {
    darkGray: '#243336',
    gold: '#c49a4d',
  },
  breakpoints: {
    values: {
      xs: 330,
      sm: 700,
      md: 960,
      lg: 1024,
      xl: 1920,
    },
  },
};
export const mobileMaxResolution = kwiffOptions.breakpoints!.values!.lg!;
export const MIN_JOURNEY_CONTAINER_WIDTH = 423;
export const MAX_MEDIUM_WIDTH = 1440;
export const TOP_BAR_HEIGHT = 56;

const createTheme = (options?: KwiffThemeOptions): TTheme => createMuiTheme({ ...kwiffOptions, ...options });

export default createTheme;
