import { default as TagManager } from 'react-gtm-module';
import { GOOGLE_TAG_MANAGER_API_KEY } from '../utils/initialisation';

export const initialiseGTM = () => {
  if (GOOGLE_TAG_MANAGER_API_KEY) {
    TagManager.initialize({
      gtmId: GOOGLE_TAG_MANAGER_API_KEY,
    });
  }
};
