export type Action =
  | SetUserGeoLocation
  | SetIsCasinoDomain
  | SetIsBetkwiffDomain
  | SetWebAppOrigin
  | SetConsentSnackbarOpen
  | SetConsentDialogOpen
  | CloseConsentPrompts;

export const SET_USER_GEO_LOCATION = 'SET_USER_GEO_LOCATION';
export const SET_IS_CASINO_DOMAIN = 'SET_IS_CASINO_DOMAIN';
export const SET_IS_BETKWIFF_DOMAIN = 'SET_IS_BETKWIFF_DOMAIN';
export const SET_WEBAPP_ORIGIN = 'SET_WEBAPP_ORIGIN';
export const SET_CONSENT_SNACKBAR_OPEN = 'SET_CONSENT_SNACKBAR_OPEN';
export const SET_CONSENT_DIALOG_OPEN = 'SET_CONSENT_DIALOG_OPEN';
export const CLOSE_CONSENT_PROMPTS = 'CLOSE_CONSENT_PROMPTS';

export interface TStore {
  geoLocation: string;
  isCasinoDomain: boolean;
  isBetkwiffDomain: boolean;
  webAppOrigin: string;
  consentSnackbarOpen: boolean;
  consentDialogOpen: boolean;
}

interface SetUserGeoLocation {
  type: typeof SET_USER_GEO_LOCATION;
  meta: string;
}

interface SetIsCasinoDomain {
  type: typeof SET_IS_CASINO_DOMAIN;
  meta: boolean;
}

interface SetIsBetkwiffDomain {
  type: typeof SET_IS_BETKWIFF_DOMAIN;
  meta: boolean;
}

interface SetWebAppOrigin {
  type: typeof SET_WEBAPP_ORIGIN;
  meta: string;
}

interface SetConsentSnackbarOpen {
  type: typeof SET_CONSENT_SNACKBAR_OPEN;
  meta: boolean;
}

interface SetConsentDialogOpen {
  type: typeof SET_CONSENT_DIALOG_OPEN;
  meta: boolean;
}

interface CloseConsentPrompts {
  type: typeof CLOSE_CONSENT_PROMPTS;
}
